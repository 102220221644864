import { Button } from '@chakra-ui/button'
import { useColorModeValue } from '@chakra-ui/color-mode'
import Icon from '@chakra-ui/icon'
import { Box, CircularProgress } from '@chakra-ui/react'
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table'
import { Tag } from '@chakra-ui/tag'
import React from 'react'
import { FaPlus, FaUsers } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'

import PageTitle from '../PageTitle'
import { useUsersQuery } from './users.generated'

const Users: React.FC = () => {
  const { data, loading, error } = useUsersQuery({
    fetchPolicy: 'cache-and-network',
  })

  const theadBgColor = useColorModeValue('gray.50', 'gray.800')

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress isIndeterminate />
      </Box>
    )
  }

  if (error || !data) {
    return <div>Error</div>
  }

  return (
    <>
      <PageTitle
        action={
          <Button
            as={NavLink}
            leftIcon={<Icon as={FaPlus} />}
            to="/users/new"
            variant="outline"
          >
            New User
          </Button>
        }
        icon={FaUsers}
        title="Users"
      />
      <Table borderWidth="thin" my={8} variant="simple" aria-label="Users">
        <Thead bgColor={theadBgColor}>
          <Tr>
            <Th>User</Th>
            <Th>Role</Th>
            <Th>Status</Th>
            <Th>&nbsp;</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.users.map((user) => (
            <Tr key={user.id}>
              <Td>{user.name}</Td>
              <Td>{user.role.name}</Td>
              <Td>
                <Tag colorScheme="green">Active</Tag>
              </Td>
              <Td width={8}>
                <Button
                  as={NavLink}
                  color="blue.500"
                  to={`/users/${user.id}`}
                  variant="link"
                >
                  Edit
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  )
}

export default Users
