import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Progress,
  Text,
  useColorMode,
  useToast,
  VStack,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { FaMoon, FaRegEye, FaRegEyeSlash, FaSun } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import zxcvbn from 'zxcvbn'

import logo from '../../assets/logo.svg'
import { required } from '../../utils/validators/required'

type FormFields = {
  password: string
  confirmPassword: string
}

const UserSetPassword: React.FC = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const { colorMode, toggleColorMode } = useColorMode()

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const handleSubmit = () => {
    toast({
      description: `Your password is saved. You can login to Aerodesk with your credentials now.`,
      duration: 9000,
      isClosable: true,
      status: 'success',
      title: 'Password saved',
    })
    navigate('/login')
  }

  const validatePasswordMatch = (values: FormFields) => {
    if (values.password !== values.confirmPassword) {
      return { confirmPassword: 'The passwords do not match' }
    }
  }

  return (
    <main>
      <Box display="flex" minHeight="100vh">
        <Box
          display="flex"
          flex={{ base: 1, lg: 'none' }}
          flexDirection="column"
          px={{ base: 4, sm: 6, lg: 20, xl: 24 }}
          py={{ base: 12 }}
        >
          <Box mx="auto" width={{ base: 'full', lg: '96' }} maxWidth="sm">
            <Box>
              <Image
                alt="Occurrency Report"
                src={logo}
                height={12}
                width="auto"
              />
              <Heading as="h2" fontWeight="extrabold" mt={6} size="lg">
                Set your password
              </Heading>
              <Text as="p" fontSize="sm" mt={2}>
                Define your custom password to access Aerodesk
              </Text>
            </Box>
            <Box mt={6}>
              <Form
                // initialValues={{ ...formValues }}
                onSubmit={handleSubmit}
                render={({ submitError, handleSubmit, submitting }) => (
                  <form onSubmit={handleSubmit}>
                    <VStack spacing={6}>
                      <Field
                        name="password"
                        render={({ input, meta: { error, touched } }) => (
                          <FormControl
                            isInvalid={error && touched}
                            maxWidth="xl"
                            mt={8}
                          >
                            <FormLabel htmlFor="password">Password</FormLabel>
                            <InputGroup>
                              <Input
                                {...input}
                                autoComplete="off"
                                id="password"
                                placeholder="Password"
                                type={showPassword ? 'text' : 'password'}
                              />
                              <InputRightElement>
                                <IconButton
                                  aria-label={showPassword ? 'Hide' : 'Show'}
                                  icon={
                                    <Icon
                                      as={
                                        showPassword ? FaRegEyeSlash : FaRegEye
                                      }
                                    />
                                  }
                                  onClick={() =>
                                    setShowPassword((prev) => !prev)
                                  }
                                  variant="ghost"
                                />
                              </InputRightElement>
                            </InputGroup>
                            <FormErrorMessage>{error}</FormErrorMessage>
                          </FormControl>
                        )}
                        validate={required}
                      />

                      <Field
                        name="confirmPassword"
                        render={({ input, meta: { error, touched } }) => (
                          <FormControl
                            isInvalid={error && touched}
                            maxWidth="xl"
                            mt={8}
                          >
                            <FormLabel htmlFor="confirmPassword">
                              Confirm password
                            </FormLabel>
                            <InputGroup>
                              <Input
                                {...input}
                                autoComplete="off"
                                id="confirmPassword"
                                placeholder="Confirm password"
                                type={showConfirmPassword ? 'text' : 'password'}
                              />
                              <InputRightElement>
                                <IconButton
                                  aria-label={
                                    showConfirmPassword ? 'Hide' : 'Show'
                                  }
                                  icon={
                                    <Icon
                                      as={
                                        showConfirmPassword
                                          ? FaRegEyeSlash
                                          : FaRegEye
                                      }
                                    />
                                  }
                                  onClick={() =>
                                    setShowConfirmPassword((prev) => !prev)
                                  }
                                  variant="ghost"
                                />
                              </InputRightElement>
                            </InputGroup>
                            <FormErrorMessage>{error}</FormErrorMessage>
                          </FormControl>
                        )}
                        validate={required}
                      />

                      <Field
                        name="password"
                        render={({ input }) => {
                          const passwordStrength = input.value
                            ? (zxcvbn(input.value).score + 1) * 20
                            : 0

                          return (
                            <FormControl width="full">
                              <FormLabel as="span">Password strength</FormLabel>
                              <Progress
                                aria-label="Password Strength"
                                colorScheme={
                                  passwordStrength < 20
                                    ? 'gray'
                                    : passwordStrength < 60
                                    ? 'red'
                                    : passwordStrength < 80
                                    ? 'yellow'
                                    : 'green'
                                }
                                mt={2}
                                value={passwordStrength}
                              />
                              <FormHelperText>
                                The stronger your password is, the more secure
                                your account is.
                              </FormHelperText>
                            </FormControl>
                          )
                        }}
                      />

                      <Button
                        colorScheme="blue"
                        isLoading={submitting}
                        loadingText="Signin in"
                        type="submit"
                        width="full"
                      >
                        Save
                      </Button>

                      {submitError && (
                        <Box color="red.600" fontWeight="medium">
                          Es ist ein Fehler aufgetreten
                        </Box>
                      )}
                    </VStack>
                  </form>
                )}
                validate={validatePasswordMatch}
              />
            </Box>
          </Box>
        </Box>
        <Box
          display={{ base: 'none', lg: 'block' }}
          flex="1"
          position="relative"
        >
          <Image
            height="100%"
            objectFit="cover"
            position="absolute"
            inset="0"
            src="https://images.unsplash.com/photo-1556388158-158ea5ccacbd?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80"
            width="100%"
          />
        </Box>
      </Box>
      <Box bottom={12} left={12} position="fixed">
        <Button onClick={toggleColorMode}>
          <Icon
            aria-label="Farbschema ändern"
            as={colorMode === 'light' ? FaMoon : FaSun}
          />
        </Button>
      </Box>
    </main>
  )
}

export default UserSetPassword
