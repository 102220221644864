import jwt_decode from 'jwt-decode'
import moment from 'moment'
import { graphql, PathParams, rest } from 'msw'

import {
  DashboardQuery,
  DashboardQueryVariables,
} from '../../components/Dashboard/dashboard.generated'
import {
  CreateReportMutation,
  CreateReportMutationVariables,
} from '../../components/ReportNew/createReport.generated'
import {
  CreateCommentMutation,
  CreateCommentMutationVariables,
  ReportViewQuery,
  ReportViewQueryVariables,
  UpdateReportMutation,
  UpdateReportMutationVariables,
} from '../../components/ReportView/ReportView.generated'
import {
  UserEditQuery,
  UserEditQueryVariables,
  UserEditRolesQuery,
  UserEditRolesQueryVariables,
} from '../../components/UserEdit/userEdit.generated'
import {
  CreateUserMutation,
  CreateUserMutationVariables,
  UserNewCheckUserQuery,
  UserNewCheckUserQueryVariables,
  UserNewRolesQuery,
  UserNewRolesQueryVariables,
} from '../../components/UserNew/usersNew.generated'
import {
  UsersQuery,
  UsersQueryVariables,
} from '../../components/Users/users.generated'
import { StateType } from '../../generated/types'
import { authURL } from '../config'
import { db } from './db'

type SigninRequest = {
  username: string
  password: string
}

type SigninResponse = string

type TokenRequest = {
  account: string
}

type TokenResponse = string

export const handlers = [
  // Authentication
  rest.post<SigninRequest, PathParams, SigninResponse>(
    `${authURL}/signin`,
    (request, response, context) => {
      const { username, password } = request.body

      const dbUser = db.user.findFirst({
        where: {
          name: {
            equals: username,
          },
        },
      })

      if (dbUser && password === 'Demo') {
        // Manually build simple JWT
        const headers = { typ: 'JWT', alg: 'HS256' }
        const payload = {
          iat: moment().unix(),
          usr: username,
          name: username,
          accounts: [
            {
              id: '123',
              name: 'Test',
            },
          ],
        }

        const segments: string[] = []
        segments.push(btoa(JSON.stringify(headers)))
        segments.push(btoa(JSON.stringify(payload)))
        segments.push('secret')

        const token = segments.join('.')
        return response(context.delay(), context.text(token))
      }

      return response(context.status(401, 'NOT_AUTHORIZED'))
    }
  ),

  rest.post<TokenRequest, PathParams, TokenResponse>(
    `${authURL}/token`,
    (request, response, context) => {
      const { account } = request.body

      const bearer = request.headers.get('bearer') || ''
      const decodedToken = jwt_decode<{ name: string }>(bearer)

      const dbUser = db.user.findFirst({
        where: {
          name: {
            equals: decodedToken.name,
          },
        },
      })

      const dbRole = db.role.findFirst({
        where: { id: { equals: dbUser?.role } },
      })

      const token = {
        account: account,
        name: decodedToken.name,
        role: dbRole?.name,
      }

      return response(context.delay(), context.text(JSON.stringify(token)))
    }
  ),

  graphql.query<DashboardQuery, DashboardQueryVariables>(
    'Dashboard',
    (request, response, context) => {
      const bearer = request.headers.get('bearer') || ''
      const token = JSON.parse(bearer) as { name: string; role: string }

      if (token.role === 'Reporter') {
        const reports = db.report.findMany({
          where: { user: { equals: token.name } },
        })

        return response(
          context.delay(),
          context.data({
            reports: reports.map((report) => {
              return {
                ...report,
                numOfComments: db.activity.findMany({
                  where: {
                    type: { equals: 'Comment' },
                    report: { id: { equals: report.id } },
                  },
                }).length,
                numOfAttachments: 0,
              }
            }),
          })
        )
      }

      const reports = db.report.getAll()

      return response(
        context.delay(),
        context.data({
          reports: reports.map((report) => {
            return {
              ...report,
              dangerousGoods: {
                checked: report.dangerousGoodsChecked,
                content: report.dangerousGoods,
              },
              numOfComments: db.activity.findMany({
                where: {
                  type: { equals: 'Comment' },
                  report: { id: { equals: report.id } },
                },
              }).length,
              numOfAttachments: 0,
            }
          }),
        })
      )
    }
  ),

  graphql.query<ReportViewQuery, ReportViewQueryVariables>(
    'ReportView',
    (request, response, context) => {
      const { id } = request.variables

      const report = db.report.findFirst({
        where: { id: { equals: id } },
      })

      const activities = db.activity.findMany({
        where: { report: { id: { equals: id } } },
      })

      if (report) {
        return response(
          context.delay(),
          context.data({
            report: {
              ...report,
              dangerousGoods: {
                checked: report.dangerousGoodsChecked,
                content: report.dangerousGoods,
              },
              attachments: [
                { id: '1', name: 'robots.txt', path: '/testPath' },
                { id: '2', name: 'robots.txt', path: '/testPath' },
              ],
              activities: activities.map((activity) => {
                if (activity.type === 'State') {
                  const data = db.state.findFirst({
                    where: { activity: { id: { equals: activity.id } } },
                  })

                  if (!data) {
                    throw Error('No related state data found...')
                  }

                  return {
                    ...activity,
                    data: { ...data, __typename: 'State' },
                  }
                }

                const data = db.comment.findFirst({
                  where: { activity: { id: { equals: activity.id } } },
                })

                if (!data) {
                  throw Error('No related comment data found...')
                }

                return {
                  ...activity,
                  data: { ...data, __typename: 'Comment' },
                }
              }),
            },
          })
        )
      }

      return response(
        context.delay(),
        context.data({
          report: null,
        })
      )
    }
  ),

  graphql.query<UserEditQuery, UserEditQueryVariables>(
    'UserEdit',
    (request, response, context) => {
      return response(
        context.delay(),
        context.data({
          users:
            db.user
              .findMany({
                where: { id: { equals: request.variables.id } },
              })
              .map((user) => ({
                ...user,
                role: {
                  id: user.role,
                },
              })) || [],
        })
      )
    }
  ),

  graphql.query<UserEditRolesQuery, UserEditRolesQueryVariables>(
    'UserEditRoles',
    (request, response, context) => {
      return response(
        context.delay(),
        context.data({
          roles: db.role.getAll(),
        })
      )
    }
  ),

  graphql.query<UserNewCheckUserQuery, UserNewCheckUserQueryVariables>(
    'UserNewCheckUser',
    (request, response, context) => {
      return response(
        context.delay(),
        context.data({
          users:
            db.user.findMany({
              where: { name: { equals: request.variables.user } },
            }) || [],
        })
      )
    }
  ),

  graphql.query<UserNewRolesQuery, UserNewRolesQueryVariables>(
    'UserNewRoles',
    (request, response, context) => {
      return response(
        context.delay(),
        context.data({
          roles: db.role.getAll(),
        })
      )
    }
  ),

  graphql.query<UsersQuery, UsersQueryVariables>(
    'Users',
    (request, response, context) => {
      return response(
        context.delay(),
        context.data({
          users: db.user.getAll().map((user) => ({
            id: user.id,
            name: user.name,
            role: {
              name:
                db.role.findFirst({ where: { id: { equals: user.role } } })
                  ?.name || '',
            },
          })),
        })
      )
    }
  ),

  graphql.mutation<CreateCommentMutation, CreateCommentMutationVariables>(
    'CreateComment',
    (request, response, context) => {
      if (request.headers.has('bearer')) {
        const report = db.report.findFirst({
          where: { id: { equals: request.variables.report } },
        })

        if (report) {
          const bearer = request.headers.get('bearer') || ''
          const token = JSON.parse(bearer) as { name: string }

          const activity = db.activity.create({
            report: report,
            timestamp: new Date().getTime(),
            user: token.name,
            type: 'Comment',
          })

          const comment = db.comment.create({
            comment: request.variables.comment,
            activity: activity,
          })

          return response(
            context.delay(),
            context.data({ createComment: { data: comment } })
          )
        }

        return response(
          context.errors([{ message: 'Report to comment to does not exist' }])
        )
      }

      return response(context.status(401, 'NOT_AUTHORIZED'))
    }
  ),

  graphql.mutation<CreateReportMutation, CreateReportMutationVariables>(
    'CreateReport',
    (request, response, context) => {
      if (request.headers.has('bearer')) {
        const bearer = request.headers.get('bearer') || ''

        try {
          const token = JSON.parse(bearer) as { name: string }

          const report = db.report.create({
            location: request.variables.location,
            date: request.variables.date,
            state: StateType.Active,
            user: request.variables.anonym === false ? token.name : undefined,
            created: new Date().getTime(),
          })
          return response(
            context.delay(),
            context.data({ createReport: { data: { id: report.id } } })
          )
        } catch (err) {
          return response(context.data({ createReport: { data: null } }))
        }
      }

      return response(context.status(401, 'NOT_AUTHORIZED'))
    }
  ),

  graphql.mutation<CreateUserMutation, CreateUserMutationVariables>(
    'CreateUser',
    (request, response, context) => {
      if (request.headers.has('bearer')) {
        const user = db.user.create({
          name: request.variables.user,
          role: request.variables.role,
        })

        return response(
          context.delay(),
          context.data({ createUser: { data: user } })
        )
      }

      return response(context.status(401, 'NOT_AUTHORIZED'))
    }
  ),

  graphql.mutation<UpdateReportMutation, UpdateReportMutationVariables>(
    'UpdateReport',
    (request, response, context) => {
      if (request.headers.has('bearer')) {
        const { id } = request.variables

        const report = db.report.findFirst({
          where: { id: { equals: id } },
        })

        if (report) {
          const bearer = request.headers.get('bearer') || ''
          const token = JSON.parse(bearer) as { name: string }

          if (request.variables.state) {
            db.report.update({
              where: { id: { equals: id } },
              data: { state: request.variables.state },
            })

            const activity = db.activity.create({
              report: report,
              timestamp: new Date().getTime(),
              user: token.name,
              type: 'State',
            })

            db.state.create({
              state: request.variables.state,
              activity: activity,
            })
          }

          return response(context.data({ updateReport: { data: report } }))
        }

        return response(
          context.errors([{ message: 'Report to update does not exist' }])
        )
      }

      return response(context.status(401, 'NOT_AUTHORIZED'))
    }
  ),
]
