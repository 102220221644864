import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import jwt_decode from 'jwt-decode'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { authURL, storageKeys } from '../../utils/config'

type AuthenticatedUser = {
  name: string
  accounts: { id: string; name: string }[]
  token: string
}

type AuthorizedUser = {
  name: string
  token: string
}

type Auth = {
  user?: AuthorizedUser
  signin: (
    username: string,
    passwod: string,
    remember: boolean
  ) => Promise<void>
  signout: () => Promise<void>
}

const AuthContext = createContext<Auth | null>(null)

const AuthProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<{
    authenticatedUser?: AuthenticatedUser
    authorizedUser?: AuthorizedUser
    remember: boolean
  }>({
    ...getUserFromStorage(),
    remember: false,
  })
  const [account, setAccount] = useState<string | undefined>(undefined)

  const signin = async (name: string, password: string, remember: boolean) => {
    try {
      const response: Response = await fetch(`${authURL}/signin`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          username: name,
          password: password,
        }),
      })
      const result = await response.text()

      const token =
        jwt_decode<{ name: string; accounts: [{ id: string; name: string }] }>(
          result
        )

      setState({
        remember: remember,
        authenticatedUser: {
          name: token.name,
          accounts: token.accounts,
          token: result,
        },
      })

      return Promise.resolve()
    } catch (error) {
      return Promise.reject()
    }
  }

  const signout = () => {
    setState({
      remember: false,
      authenticatedUser: undefined,
      authorizedUser: undefined,
    })
    localStorage.removeItem(storageKeys.AERODESK_USER)
    return Promise.resolve()
  }

  const chooseAccount = useCallback(
    async (account: string) => {
      if (state.authenticatedUser) {
        const response: Response = await fetch(`${authURL}/token`, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            bearer: state.authenticatedUser.token,
          },
          body: JSON.stringify({
            account: account,
          }),
        })

        const result = await response.text()

        const user: AuthorizedUser = {
          name: state.authenticatedUser?.name || '',
          token: result,
        }

        if (state.remember) {
          localStorage.setItem(
            storageKeys.AERODESK_USER,
            btoa(
              JSON.stringify({
                authenticatedUser: state.authenticatedUser,
                authorizedUser: user,
              })
            )
          )
        }

        setState((prev) => ({
          ...prev,
          authorizedUser: user,
        }))
      } else {
        setState((prev) => ({ ...prev, authorizedUser: undefined }))
      }
    },
    [state.authenticatedUser, state.remember]
  )

  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (state.authenticatedUser && !state.authorizedUser) {
      const { accounts } = state.authenticatedUser
      if (accounts.length === 1) {
        chooseAccount(accounts[0].id)
      } else {
        onOpen()
      }
    }
  }, [state.authenticatedUser, state.authorizedUser, onOpen, chooseAccount])

  useEffect(() => {
    if (state.authorizedUser) {
      onClose()
    }
  }, [state, onClose])

  const handleContinue = () => {
    if (account) {
      chooseAccount(account)
      setAccount(undefined)
    }
  }

  return (
    <AuthContext.Provider
      value={{ user: state.authorizedUser, signin, signout }}
    >
      {children}
      <Modal
        closeOnEsc={false}
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay style={{ backdropFilter: 'blur(4px)' }} />
        <ModalContent>
          <ModalHeader>Choose your organization</ModalHeader>
          <ModalBody pb={6}>
            <Text>
              You are assigned to more than one organization. Please select the
              organization you want to work.
            </Text>
            <RadioGroup mt={6} name="account" onChange={setAccount}>
              <Stack>
                {state.authenticatedUser?.accounts.map((account) => (
                  <Radio key={account.id} value={account.id}>
                    {account.name}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              isDisabled={account === undefined}
              mr={3}
              onClick={handleContinue}
            >
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </AuthContext.Provider>
  )
}

export const useAuth = (): Auth => {
  const value = useContext(AuthContext)

  if (value === null) {
    throw new Error('useAuth must be used within <AuthProvider />')
  }

  return value
}

const getUserFromStorage = (): {
  authenticatedUser?: AuthenticatedUser
  authorizedUser?: AuthorizedUser
} => {
  const storedData = localStorage.getItem(storageKeys.AERODESK_USER)

  if (storedData) {
    try {
      const parsedData = JSON.parse(atob(storedData))
      if (
        parsedData &&
        parsedData['authenticatedUser'] &&
        parsedData['authorizedUser']
      ) {
        return {
          authenticatedUser: parsedData['authenticatedUser'],
          authorizedUser: parsedData['authorizedUser'],
        }
      }
    } catch {}
  }

  return {
    authenticatedUser: undefined,
    authorizedUser: undefined,
  }
}

export default AuthProvider
