import Icon from '@chakra-ui/icon'
import { Box, Heading, HStack } from '@chakra-ui/layout'
import React from 'react'
import { IconType } from 'react-icons'

type PageTitleProps = {
  action?: React.ReactNode
  icon?: IconType
  subtitle?: React.ReactNode
  title: string
}

const PageTitle: React.FC<PageTitleProps> = ({
  action,
  icon,
  subtitle,
  title,
}) => {
  return (
    <Box mb={8}>
      <HStack justify="space-between">
        <Heading as="h1" display="flex" alignItems="center" mb={4}>
          {icon && <Icon as={icon} color="gray.500" mr={4} role="img" />}
          {title}
        </Heading>
        {action}
      </HStack>
      {subtitle}
    </Box>
  )
}

export default PageTitle
