import { useLayoutEffect, useState } from 'react'

export const useMediaQuery = (query: string): boolean => {
  const isSupported =
    typeof window !== 'undefined' && typeof window.matchMedia !== 'undefined'

  const mediaQuery = window.matchMedia(query)
  const [match, setMatch] = useState(isSupported ? !!mediaQuery.matches : false)

  useLayoutEffect(() => {
    if (!isSupported) {
      return undefined
    }

    let isSubscribed = true

    const handler = () => {
      isSubscribed && setMatch(!!mediaQuery.matches)
    }
    mediaQuery.addListener(handler)

    return () => {
      isSubscribed = false
      mediaQuery.removeListener(handler)
    }
  }, [query, mediaQuery, isSupported])

  return match
}
