import TimeAgo from 'react-timeago'

export const formatter = (
  value: number,
  unit: TimeAgo.Unit,
  suffix: TimeAgo.Suffix
): string => {
  if (unit === 'second') {
    return 'just now'
  }

  const plural = value !== 1 ? 's' : ''
  return `${value} ${unit}${plural} ${suffix}`
}
