import React from 'react'
import ReactDOM from 'react-dom'

import App from './components/App'
import reportWebVitals from './reportWebVitals'
import { worker } from './utils/msw/browser'
import { seedReports } from './utils/msw/db'

const render = () => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  )
}

if (false && process.env.NODE_ENV === 'development') {
  seedReports()
  worker.start().then(() => {
    render()
  })
} else {
  render()
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
