import * as Types from '../../generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type UserEditQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']
}>

export type UserEditQuery = {
  __typename?: 'Query'
  users: Array<{
    __typename?: 'User'
    id: string
    name: string
    role: { __typename?: 'Role'; id: string }
  }>
}

export type UserEditRolesQueryVariables = Types.Exact<{ [key: string]: never }>

export type UserEditRolesQuery = {
  __typename?: 'Query'
  roles: Array<{ __typename?: 'Role'; id: string; name: string }>
}

export const UserEditDocument = gql`
  query UserEdit($id: ID!) {
    users(filter: { id: { _eq: $id } }) {
      id
      name
      role {
        id
      }
    }
  }
`

/**
 * __useUserEditQuery__
 *
 * To run a query within a React component, call `useUserEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserEditQuery(
  baseOptions: Apollo.QueryHookOptions<UserEditQuery, UserEditQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserEditQuery, UserEditQueryVariables>(
    UserEditDocument,
    options
  )
}
export function useUserEditLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserEditQuery,
    UserEditQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserEditQuery, UserEditQueryVariables>(
    UserEditDocument,
    options
  )
}
export type UserEditQueryHookResult = ReturnType<typeof useUserEditQuery>
export type UserEditLazyQueryHookResult = ReturnType<
  typeof useUserEditLazyQuery
>
export type UserEditQueryResult = Apollo.QueryResult<
  UserEditQuery,
  UserEditQueryVariables
>
export const UserEditRolesDocument = gql`
  query UserEditRoles {
    roles {
      id
      name
    }
  }
`

/**
 * __useUserEditRolesQuery__
 *
 * To run a query within a React component, call `useUserEditRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEditRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEditRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserEditRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserEditRolesQuery,
    UserEditRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserEditRolesQuery, UserEditRolesQueryVariables>(
    UserEditRolesDocument,
    options
  )
}
export function useUserEditRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserEditRolesQuery,
    UserEditRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserEditRolesQuery, UserEditRolesQueryVariables>(
    UserEditRolesDocument,
    options
  )
}
export type UserEditRolesQueryHookResult = ReturnType<
  typeof useUserEditRolesQuery
>
export type UserEditRolesLazyQueryHookResult = ReturnType<
  typeof useUserEditRolesLazyQuery
>
export type UserEditRolesQueryResult = Apollo.QueryResult<
  UserEditRolesQuery,
  UserEditRolesQueryVariables
>
