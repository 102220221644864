import { useApolloClient } from '@apollo/client'
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { Field, Form } from 'react-final-form'
import {
  FaRegAddressCard,
  FaSave,
  FaTimes,
  FaUser,
  FaUserCircle,
} from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import { composeValidators } from '../../utils/validators/composeValidators'
import { mustBeEmail } from '../../utils/validators/mustBeEmail'
import { required } from '../../utils/validators/required'
import PageTitle from '../PageTitle'
import Fieldset from '../ReportNew/Fieldset'
import Legend from '../ReportNew/Legend'
import {
  useCreateUserMutation,
  UserNewCheckUserDocument,
  useUserNewRolesQuery,
} from './usersNew.generated'

type FormFields = {
  user: string
  role: string
  photo?: string
}

const UserNew: React.FC = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const client = useApolloClient()

  const descriptionTextColor = useColorModeValue('gray.500', 'gray.400')

  const { data, loading } = useUserNewRolesQuery()
  const [createUser] = useCreateUserMutation()

  const [avatar, setAvatar] = useState<string | undefined>(undefined)

  const formValues: FormFields = {
    user: '',
    role: '',
    photo: undefined,
  }

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress isIndeterminate />
      </Box>
    )
  }

  const handleCancel = () => {
    navigate(-1)
  }

  // TODOS:
  // 1. Passwort strength einbauen ✔️
  // 2. CHeck für Benutzername einbauen ✔️
  // 3. Email Validierung einbauen ✔️
  // 4. Feld für Nutzernamen einbauen

  const handleFormSubmit = async (values: FormFields) => {
    const { data } = await client.query({
      fetchPolicy: 'network-only',
      query: UserNewCheckUserDocument,
      variables: { user: values.user },
    })

    if (data && data.users.length > 0) {
      return {
        user: 'This address is already in use. Please enter another one',
      }
    }

    try {
      await createUser({
        variables: {
          user: values.user,
          role: values.role,
        },
      })
      toast({
        description: `User '${values.user}' was successfully created.`,
        duration: 9000,
        isClosable: true,
        status: 'success',
        title: 'User created',
      })
      navigate('/users')
    } catch {
      toast({
        description: 'Please try again',
        duration: 9000,
        isClosable: true,
        status: 'error',
        title: 'An error occurred',
      })
    }
  }

  const handlePhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.item(0)

    if (file) {
      const fileReader = new FileReader()
      fileReader.onload = (event: ProgressEvent<FileReader>) => {
        if (typeof event.target?.result === 'string') {
          setAvatar(`data:image/png;base64,${btoa(event.target?.result)}`)
        }
      }
      fileReader.readAsBinaryString(file)
    }
  }

  const handlePhotoDelete = () => {
    setAvatar(undefined)
  }

  return (
    <>
      <PageTitle icon={FaUser} title="New user" />
      <Form
        initialValues={{ ...formValues }}
        onSubmit={handleFormSubmit}
        render={({ handleSubmit, submitting }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Fieldset>
              <Legend icon={FaUserCircle}>Account information</Legend>
              <Text color={descriptionTextColor} fontSize="sm" mt={-4}>
                General information about the user.
              </Text>
              <Field
                name="user"
                render={({
                  input,
                  meta: { dirtySinceLastSubmit, error, submitError, touched },
                }) => (
                  <FormControl
                    isInvalid={
                      (error || (submitError && !dirtySinceLastSubmit)) &&
                      touched
                    }
                    isRequired
                    maxWidth="xl"
                    mt={8}
                  >
                    <FormLabel htmlFor="user">Email address</FormLabel>
                    <InputGroup>
                      <Input
                        {...input}
                        autoComplete="off"
                        id="user"
                        placeholder="Email address"
                        type="email"
                      />
                      {submitting && (
                        <InputRightElement>
                          <CircularProgress
                            isIndeterminate
                            color="blue.300"
                            size="1rem"
                          />
                        </InputRightElement>
                      )}
                    </InputGroup>
                    <FormErrorMessage>{error || submitError}</FormErrorMessage>
                  </FormControl>
                )}
                validate={composeValidators(required, mustBeEmail)}
              />

              <Field
                name="role"
                render={({ input, meta: { error, touched } }) => (
                  <FormControl
                    isInvalid={error && touched}
                    isRequired
                    maxWidth="xl"
                    mt={8}
                  >
                    <FormLabel htmlFor="role">Role</FormLabel>
                    <Select {...input} id="role" placeholder="Select...">
                      {data &&
                        data.roles.map((role) => (
                          <option key={role.id} value={role.id}>
                            {role.name}
                          </option>
                        ))}
                    </Select>
                    <FormErrorMessage>{error}</FormErrorMessage>
                  </FormControl>
                )}
                validate={required}
              />
            </Fieldset>

            <Fieldset>
              <Legend icon={FaRegAddressCard}>Personal information</Legend>
              <Text color={descriptionTextColor} fontSize="sm" mt={-4}>
                Detailed information about the user. This information will not
                be displayed.
              </Text>

              <Grid
                templateColumns={{
                  base: 'repeat(1, 1fr)',
                  md: 'repeat(2, 1fr)',
                }}
                gap={6}
                maxWidth="3xl"
              >
                <Field
                  name="firstname"
                  render={({ input }) => (
                    <FormControl maxWidth="md" mt={8}>
                      <FormLabel htmlFor="firstname">First name</FormLabel>
                      <Input
                        {...input}
                        autoComplete="off"
                        id="firstname"
                        placeholder="First name"
                        type="text"
                      />
                    </FormControl>
                  )}
                />

                <Field
                  name="lastname"
                  render={({ input }) => (
                    <FormControl maxWidth="md" mt={8}>
                      <FormLabel htmlFor="lastname">Last name</FormLabel>
                      <Input
                        {...input}
                        autoComplete="off"
                        id="lastname"
                        placeholder="Last name"
                        type="text"
                      />
                    </FormControl>
                  )}
                />
              </Grid>

              <FormControl maxWidth="md" mt={8}>
                <FormLabel>Photo</FormLabel>
                <Box alignItems="center" display="flex" mt={4}>
                  <Avatar size="xl" src={avatar} />
                  <Box ml={5}>
                    <Stack direction="row" spacing={4}>
                      <Button variant="outline">
                        <Box as="label" htmlFor="photo">
                          Change
                        </Box>
                      </Button>
                      {avatar && (
                        <Button
                          colorScheme="red"
                          onClick={handlePhotoDelete}
                          variant="ghost"
                        >
                          Delete
                        </Button>
                      )}
                    </Stack>
                    <Text color="gray.500" fontSize="sm" mt={3}>
                      .jpg, .png, or .heic. Max file size 1500k.
                    </Text>
                  </Box>
                </Box>
                <Input
                  accept=".jpg,.jpeg,.png,.heic"
                  hidden
                  id="photo"
                  onChange={handlePhotoChange}
                  type="file"
                />
              </FormControl>
            </Fieldset>

            <HStack spacing={4}>
              <Button
                colorScheme="blue"
                disabled={submitting}
                leftIcon={<Icon as={FaSave} />}
                type="submit"
              >
                Create
              </Button>
              <Button
                disabled={submitting}
                leftIcon={<Icon as={FaTimes} />}
                onClick={handleCancel}
                variant="outline"
              >
                Cancel
              </Button>
            </HStack>
          </form>
        )}
        subscription={{ pristine: true, submitting: true }}
      />
    </>
  )
}

export default UserNew
