import { Button } from '@chakra-ui/button'
import { useColorModeValue } from '@chakra-ui/color-mode'
import { FormControl, FormLabel } from '@chakra-ui/form-control'
import Icon from '@chakra-ui/icon'
import {
  Box,
  Divider,
  Grid,
  GridItem,
  Heading,
  HStack,
  Link,
  List,
  ListItem,
  Text,
  VStack,
} from '@chakra-ui/layout'
import { useBreakpointValue } from '@chakra-ui/media-query'
import { CircularProgress } from '@chakra-ui/progress'
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table'
import { Tag } from '@chakra-ui/tag'
import { Textarea } from '@chakra-ui/textarea'
import { useToast } from '@chakra-ui/toast'
import moment from 'moment'
import React, { useState } from 'react'
import type { IconType } from 'react-icons'
import {
  FaArrowRight,
  FaCarCrash,
  FaClipboardList,
  FaCloudSun,
  FaExchangeAlt,
  FaFlag,
  FaNotesMedical,
  FaPaperclip,
  FaPlane,
  FaRedo,
  FaRegCalendarCheck,
  FaRegCheckCircle,
  FaRegComment,
  FaSadTear,
} from 'react-icons/fa'
import { useParams } from 'react-router'
import { NavLink } from 'react-router-dom'
import TimeAgo from 'react-timeago'

import { StateType } from '../../generated/types'
import { formatter } from '../../utils/formatter'
import PageTitle from '../PageTitle'
import { Activities } from './Activities/Activities'
import {
  useCreateCommentMutation,
  useReportViewQuery,
  useUpdateReportMutation,
} from './ReportView.generated'

type SectionHeadingProps = { icon: IconType } & React.ComponentProps<typeof Box>

const SectionHeading: React.FC<SectionHeadingProps> = ({
  icon,
  children,
  ...rest
}) => {
  return (
    <Heading
      as="h2"
      alignItems="center"
      display="flex"
      fontSize="xl"
      fontWeight="medium"
      pt={8}
      pb={2}
      {...rest}
    >
      <Icon as={icon} color="gray.500" mr={2} />
      {children}
    </Heading>
  )
}

const ReportLabel: React.FC = ({ children }) => {
  return (
    <Box color="gray.500" fontSize="sm" fontWeight="medium">
      {children}
    </Box>
  )
}

const ReportValue: React.FC = ({ children }) => {
  return (
    <Box fontSize="sm" mt={1}>
      {children}
    </Box>
  )
}

const ReportView: React.FC = () => {
  const toast = useToast()
  const { id = '' } = useParams()
  const [comment, setComment] = useState('')
  const [updatingReport, setUpdatingReport] = useState(false)

  const reportBorderColor = useColorModeValue('blue.100', 'blue.700')
  const attachmentsBorderColor = useColorModeValue('gray.200', 'gray.700')
  const attachmentsIconColor = useColorModeValue('gray.400', 'gray.600')
  const tableSize = useBreakpointValue({ base: 'sm', md: 'md' })

  const { data, loading, error, refetch } = useReportViewQuery({
    variables: { id: id },
  })

  const [updateReport] = useUpdateReportMutation()
  const [createComment, { loading: uploadComment }] = useCreateCommentMutation()

  const handleComment = async () => {
    try {
      await createComment({
        variables: {
          comment: comment,
          report: id,
        },
      })
      setComment('')
      await refetch()
    } catch {
      toast({
        description: 'Please try again',
        duration: 9000,
        isClosable: true,
        status: 'error',
        title: 'Comment could not be saved',
      })
    }
  }

  const handleCommentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setComment(event.currentTarget.value)
  }

  const handleDownload = async (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    console.log((event.target as HTMLAnchorElement).href)
    const href = (event.target as HTMLAnchorElement).href
    if (href) {
      const response = await fetch(href)
      const data = await response.blob()

      const url = URL.createObjectURL(data)
      const link = document.createElement('a')
      link.href = url
      link.download =
        (event.target as HTMLAnchorElement).getAttribute('data-label') ||
        'Attachment'
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }

  const handleUpdateStateClick = async () => {
    setUpdatingReport(true)
    try {
      switch (data?.report?.state) {
        case StateType.Active: {
          await updateReport({
            variables: { id: data.report.id, state: StateType.Closed },
          })
          break
        }

        case StateType.Closed: {
          await updateReport({
            variables: { id: data.report.id, state: StateType.Active },
          })
          break
        }
      }
      await refetch()
    } catch {
      toast({
        description: 'Please try again',
        duration: 9000,
        isClosable: true,
        status: 'error',
        title: 'Report could not be updated',
      })
    }

    setUpdatingReport(false)
  }

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress isIndeterminate />
      </Box>
    )
  }

  if (error || !data || !data.report) {
    return (
      <Box textAlign="center">
        <Icon
          as={FaSadTear}
          color="gray.400"
          height={12}
          mx="auto"
          width={12}
        />
        <Heading as="h2" mt={2} fontSize="medium" size="sm">
          Error
        </Heading>
        <Text as="p" color="gray.500" mt={1} fontSize="medium">
          Sorry, the report data could not be loaded.
        </Text>
        <Box mt={6}>
          <HStack justifyContent="center" spacing={12}>
            <Button leftIcon={<Icon as={FaRedo} />} onClick={() => refetch()}>
              Try again
            </Button>
            <Button
              as={NavLink}
              leftIcon={<Icon as={FaArrowRight} />}
              to="/dashboard"
            >
              Dashboard
            </Button>
          </HStack>
        </Box>
      </Box>
    )
  }

  return (
    <>
      <PageTitle
        icon={FaClipboardList}
        subtitle={
          <>
            <HStack spacing={{ base: 3, md: 6 }}>
              {data.report.state === StateType.Active ? (
                <Tag colorScheme="green" size="lg">
                  Active
                </Tag>
              ) : (
                <Tag colorScheme="red" size="lg">
                  Closed
                </Tag>
              )}
              <Text fontSize={{ base: 'small', md: 'medium' }}>
                {data.report.user || <i>unknown</i>} created this report{' '}
                <Text as="span" whiteSpace="nowrap">
                  <TimeAgo
                    date={moment.unix(data.report.created).toDate()}
                    formatter={formatter}
                    title={moment
                      .unix(data.report.created)
                      .format('DD.MM.YYYY HH:mm')}
                  />
                </Text>
              </Text>
            </HStack>
          </>
        }
        title="Flight Safety Report"
      />

      <Box
        border="1px"
        borderColor={reportBorderColor}
        p={{ base: 4, lg: 8 }}
        rounded="lg"
      >
        <SectionHeading icon={FaRegCalendarCheck} pt={2}>
          General data
        </SectionHeading>

        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
          gridColumnGap={4}
          gridRowGap={6}
          py={5}
        >
          <GridItem>
            <ReportLabel>Date of incident</ReportLabel>
            <ReportValue>{data.report.date || '-'}</ReportValue>
          </GridItem>
          <GridItem>
            <ReportLabel>Time (UTC)</ReportLabel>
            <ReportValue>{data.report.time || '-'}</ReportValue>
          </GridItem>
          <GridItem>
            <ReportLabel>Location</ReportLabel>
            <ReportValue>{data.report.location || '-'}</ReportValue>
          </GridItem>
          <GridItem>
            <ReportLabel>Aircraft</ReportLabel>
            <ReportValue>{data.report.aircraft || '-'}</ReportValue>
          </GridItem>
          <GridItem>
            <ReportLabel>Flight number</ReportLabel>
            <ReportValue>{data.report.flightnumber || '-'}</ReportValue>
          </GridItem>
        </Grid>

        <SectionHeading icon={FaExchangeAlt}>Flight information</SectionHeading>
        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
          gridColumnGap={4}
          gridRowGap={6}
          py={5}
        >
          <GridItem>
            <ReportLabel>Departure</ReportLabel>
            <ReportValue>{data.report.departure || '-'}</ReportValue>
          </GridItem>
          <GridItem>
            <ReportLabel>Destination</ReportLabel>
            <ReportValue>{data.report.destination || '-'}</ReportValue>
          </GridItem>
          <GridItem>
            <ReportLabel>Flight plan</ReportLabel>
            <ReportValue>{data.report.flightplan || '-'}</ReportValue>
          </GridItem>

          <GridItem>
            <ReportLabel>Pilot in command</ReportLabel>
            <ReportValue>{data.report.pilot || '-'}</ReportValue>
          </GridItem>
          <GridItem>
            <ReportLabel>Number of crew</ReportLabel>
            <ReportValue>{data.report.numOfCrew || '-'}</ReportValue>
          </GridItem>
          <GridItem>
            <ReportLabel>Number of passenger</ReportLabel>
            <ReportValue>{data.report.numOfPax || '-'}</ReportValue>
          </GridItem>

          <GridItem colSpan={3}>
            <ReportLabel>Dangerous goods</ReportLabel>
            <ReportValue>
              {data.report.dangerousGoods.checked
                ? data.report.dangerousGoods.content
                : 'None'}
            </ReportValue>
          </GridItem>
        </Grid>

        <SectionHeading icon={FaPlane}>Flight conditions</SectionHeading>
        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
          gridColumnGap={4}
          gridRowGap={6}
          py={5}
        >
          <GridItem>
            <ReportLabel>Type of flight</ReportLabel>
            <ReportValue>{data.report.flighttype || '-'}</ReportValue>
          </GridItem>
          <GridItem>
            <ReportLabel>Flight rules</ReportLabel>
            <ReportValue>{data.report.flightrules || '-'}</ReportValue>
          </GridItem>
          <GridItem>
            <ReportLabel>IAS (kt)</ReportLabel>
            <ReportValue>{data.report.ias || '-'}</ReportValue>
          </GridItem>
          <GridItem>
            <ReportLabel>Phase of flight</ReportLabel>
            <ReportValue>{data.report.flightphase || '-'}</ReportValue>
          </GridItem>
          <GridItem>
            <ReportLabel>Light conditions</ReportLabel>
            <ReportValue>{data.report.light || '-'}</ReportValue>
          </GridItem>
          <GridItem colStart={1}>
            <ReportLabel>Approach type</ReportLabel>
            <ReportValue>{data.report.approach || '-'}</ReportValue>
          </GridItem>
          <GridItem>
            <ReportLabel>Runway condition</ReportLabel>
            <ReportValue>{data.report.runway || '-'}</ReportValue>
          </GridItem>
          <GridItem>
            <ReportLabel>Airspace</ReportLabel>
            <ReportValue>{data.report.airspace || '-'}</ReportValue>
          </GridItem>
        </Grid>

        <SectionHeading icon={FaCloudSun}>Weather conditions</SectionHeading>
        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
          gridColumnGap={4}
          gridRowGap={6}
          py={5}
        >
          <GridItem>
            <ReportLabel>General</ReportLabel>
            <ReportValue>{data.report.weather || '-'}</ReportValue>
          </GridItem>
          <GridItem>
            <ReportLabel>Intensity</ReportLabel>
            <ReportValue>{data.report.weatherIntensity || '-'}</ReportValue>
          </GridItem>
          <GridItem>
            <ReportLabel>OAT (°C)</ReportLabel>
            <ReportValue>{data.report.oat || '-'}</ReportValue>
          </GridItem>
          <GridItem>
            <ReportLabel>Wind</ReportLabel>
            <ReportValue>{data.report.wind || '-'}</ReportValue>
          </GridItem>
          <GridItem>
            <ReportLabel>Direction</ReportLabel>
            <ReportValue>{data.report.windDirection || '-'}</ReportValue>
          </GridItem>
          <GridItem>
            <ReportLabel>Speed (kt)</ReportLabel>
            <ReportValue>{data.report.windSpeed || '-'}</ReportValue>
          </GridItem>
          <GridItem>
            <ReportLabel>Turbulences</ReportLabel>
            <ReportValue>{data.report.turbulences || '-'}</ReportValue>
          </GridItem>
          <GridItem>
            <ReportLabel>Icing</ReportLabel>
            <ReportValue>{data.report.icing || '-'}</ReportValue>
          </GridItem>
        </Grid>

        <SectionHeading icon={FaCarCrash}>Damages</SectionHeading>
        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
          gridColumnGap={4}
          gridRowGap={6}
          py={5}
        >
          <GridItem>
            <ReportLabel>Damage of aircraft</ReportLabel>
            <ReportValue>{data.report.damageOnAircraft || '-'}</ReportValue>
          </GridItem>
          <GridItem>
            <ReportLabel>Third Party Damage</ReportLabel>
            <ReportValue>{data.report.damageOnThird || '-'}</ReportValue>
          </GridItem>
          <GridItem colSpan={2}>
            <ReportLabel>Damage report</ReportLabel>
            <ReportValue>{data.report.damageReport || '-'}</ReportValue>
          </GridItem>
        </Grid>

        <SectionHeading icon={FaNotesMedical}>
          Medical consequences
        </SectionHeading>

        <Box overflowX="auto">
          <Table
            aria-label="Table of medical consequences"
            size={tableSize}
            variant="simple"
          >
            <Thead>
              <Tr>
                <Th>&nbsp;</Th>
                <Th>Crew</Th>
                <Th>Passenger</Th>
                <Th>Others</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>fatal</Td>
                <Td>{data.report.fatalCrew || '-'}</Td>
                <Td>{data.report.fatalPax || '-'}</Td>
                <Td>{data.report.fatalOthers || '-'}</Td>
              </Tr>
              <Tr>
                <Td>serious</Td>
                <Td>{data.report.seriousCrew || '-'}</Td>
                <Td>{data.report.seriousPax || '-'}</Td>
                <Td>{data.report.seriousOthers || '-'}</Td>
              </Tr>
              <Tr>
                <Td>minor</Td>
                <Td>{data.report.minorCrew || '-'}</Td>
                <Td>{data.report.minorPax || '-'}</Td>
                <Td>{data.report.minorOthers || '-'}</Td>
              </Tr>
              <Tr>
                <Td>none</Td>
                <Td>{data.report.noneCrew || '-'}</Td>
                <Td>{data.report.nonePax || '-'}</Td>
                <Td>{data.report.noneOthers || '-'}</Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>

        <SectionHeading icon={FaFlag}>Course of events</SectionHeading>
        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }}
          gridColumnGap={4}
          gridRowGap={6}
          py={5}
        >
          <GridItem>
            <ReportLabel>Report</ReportLabel>
            <ReportValue>
              {data.report.report
                ? data.report.report.split('\n').map((value, index) => {
                    return (
                      <React.Fragment key={index}>
                        {value}
                        <br />
                      </React.Fragment>
                    )
                  })
                : '-'}
            </ReportValue>
          </GridItem>
          {data.report.attachments.length > 0 ? (
            <GridItem>
              <ReportLabel>Attachments</ReportLabel>
              <List
                aria-label="Attachments"
                borderWidth="thin"
                borderColor={attachmentsBorderColor}
                rounded="md"
                mt={1}
              >
                {data.report.attachments.map((attachment, index) => (
                  <ListItem
                    key={attachment.id}
                    alignItems="center"
                    borderTopWidth={index > 0 ? 'thin' : undefined}
                    display="flex"
                    fontSize="sm"
                    justifyContent="space-between"
                    pl={3}
                    pr={4}
                    py={3}
                  >
                    <Box alignItems="center" display="flex" flex="1" width="0">
                      <Icon
                        as={FaPaperclip}
                        color={attachmentsIconColor}
                        mr={2}
                      />
                      <Text isTruncated>{attachment.name}</Text>
                    </Box>
                    <Box ml={4} flexShrink={0}>
                      <Link
                        color="blue.500"
                        data-label={attachment.name}
                        fontWeight="medium"
                        href={`https://files.aerodesk.de?file=${attachment.id}`}
                        onClick={handleDownload}
                        target="_blank"
                      >
                        Download
                      </Link>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </GridItem>
          ) : null}
        </Grid>
      </Box>

      <Activities activities={data.report.activities} />

      <Divider mb={4} />

      <form>
        <VStack alignItems="flex-end" spacing={4}>
          <FormControl>
            <FormLabel>Comment</FormLabel>
            <Textarea
              autoComplete="off"
              onChange={handleCommentChange}
              placeholder="Your comment..."
              rows={4}
              value={comment}
            />
          </FormControl>
          <HStack justifyContent="flex-end" spacing={4}>
            {data.report.state === StateType.Active ? (
              <Button
                disabled={updatingReport}
                leftIcon={<Icon as={FaRegCheckCircle} color="red.500" />}
                onClick={handleUpdateStateClick}
              >
                Close report
              </Button>
            ) : (
              <Button
                disabled={updatingReport}
                onClick={handleUpdateStateClick}
              >
                Reopen report
              </Button>
            )}
            <Button
              disabled={!comment || uploadComment}
              leftIcon={<Icon as={FaRegComment} />}
              onClick={handleComment}
            >
              Comment
            </Button>
          </HStack>
        </VStack>
      </form>
    </>
  )
}

export default ReportView
