import * as Types from '../../generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type ReportViewQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']
}>

export type ReportViewQuery = {
  __typename?: 'Query'
  report?: {
    __typename?: 'Report'
    id: string
    created: number
    date: string
    time: string
    location: string
    aircraft: string
    flightnumber: string
    departure: string
    destination: string
    flightplan: string
    pilot: string
    numOfCrew: string
    numOfPax: string
    flighttype: string
    flightrules: string
    ias: string
    flightphase: string
    light: string
    approach: string
    runway: string
    airspace: string
    weather: string
    weatherIntensity: string
    oat: string
    wind: string
    windDirection: string
    windSpeed: string
    turbulences: string
    icing: string
    report: string
    fatalCrew: string
    fatalPax: string
    fatalOthers: string
    seriousCrew: string
    seriousPax: string
    seriousOthers: string
    minorCrew: string
    minorPax: string
    minorOthers: string
    noneCrew: string
    nonePax: string
    noneOthers: string
    damageOnAircraft: string
    damageOnThird: string
    damageReport: string
    state: Types.StateType
    user?: string | null
    dangerousGoods: {
      __typename?: 'DangerousGoods'
      checked: boolean
      content: string
    }
    attachments: Array<{
      __typename?: 'File'
      id: string
      name: string
      path: string
    }>
    activities: Array<{
      __typename?: 'Activity'
      id: string
      timestamp: number
      user?: string | null
      data:
        | { __typename?: 'Comment'; id: string; comment: string }
        | { __typename?: 'State'; id: string; state: Types.StateType }
    }>
  } | null
}

export type CreateCommentMutationVariables = Types.Exact<{
  comment: Types.Scalars['String']
  report: Types.Scalars['ID']
}>

export type CreateCommentMutation = {
  __typename?: 'Mutation'
  createComment: {
    __typename?: 'CreateCommentPayload'
    data?: { __typename?: 'Comment'; id: string } | null
  }
}

export type UpdateReportMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']
  state: Types.StateType
}>

export type UpdateReportMutation = {
  __typename?: 'Mutation'
  updateReport: {
    __typename?: 'UpdateReportPayload'
    data: { __typename?: 'Report'; id: string }
  }
}

export const ReportViewDocument = gql`
  query ReportView($id: ID!) {
    report(id: $id) {
      id
      created
      date
      time
      location
      aircraft
      flightnumber
      departure
      destination
      flightplan
      pilot
      numOfCrew
      numOfPax
      dangerousGoods {
        checked
        content
      }
      flighttype
      flightrules
      ias
      flightphase
      light
      approach
      runway
      airspace
      weather
      weatherIntensity
      oat
      wind
      windDirection
      windSpeed
      turbulences
      icing
      report
      fatalCrew
      fatalPax
      fatalOthers
      seriousCrew
      seriousPax
      seriousOthers
      minorCrew
      minorPax
      minorOthers
      noneCrew
      nonePax
      noneOthers
      damageOnAircraft
      damageOnThird
      damageReport
      attachments {
        id
        name
        path
      }
      state
      activities {
        id
        data {
          ... on Comment {
            id
            comment
          }
          ... on State {
            id
            state
          }
        }
        timestamp
        user
      }
      user
    }
  }
`

/**
 * __useReportViewQuery__
 *
 * To run a query within a React component, call `useReportViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReportViewQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportViewQuery,
    ReportViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ReportViewQuery, ReportViewQueryVariables>(
    ReportViewDocument,
    options
  )
}
export function useReportViewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportViewQuery,
    ReportViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ReportViewQuery, ReportViewQueryVariables>(
    ReportViewDocument,
    options
  )
}
export type ReportViewQueryHookResult = ReturnType<typeof useReportViewQuery>
export type ReportViewLazyQueryHookResult = ReturnType<
  typeof useReportViewLazyQuery
>
export type ReportViewQueryResult = Apollo.QueryResult<
  ReportViewQuery,
  ReportViewQueryVariables
>
export const CreateCommentDocument = gql`
  mutation CreateComment($comment: String!, $report: ID!) {
    createComment(input: { comment: $comment, report: $report }) {
      data {
        id
      }
    }
  }
`
export type CreateCommentMutationFn = Apollo.MutationFunction<
  CreateCommentMutation,
  CreateCommentMutationVariables
>

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      comment: // value for 'comment'
 *      report: // value for 'report'
 *   },
 * });
 */
export function useCreateCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCommentMutation,
    CreateCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateCommentMutation,
    CreateCommentMutationVariables
  >(CreateCommentDocument, options)
}
export type CreateCommentMutationHookResult = ReturnType<
  typeof useCreateCommentMutation
>
export type CreateCommentMutationResult =
  Apollo.MutationResult<CreateCommentMutation>
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateCommentMutation,
  CreateCommentMutationVariables
>
export const UpdateReportDocument = gql`
  mutation UpdateReport($id: ID!, $state: StateType!) {
    updateReport(input: { id: $id, state: $state }) {
      data {
        id
      }
    }
  }
`
export type UpdateReportMutationFn = Apollo.MutationFunction<
  UpdateReportMutation,
  UpdateReportMutationVariables
>

/**
 * __useUpdateReportMutation__
 *
 * To run a mutation, you first call `useUpdateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportMutation, { data, loading, error }] = useUpdateReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUpdateReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReportMutation,
    UpdateReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateReportMutation,
    UpdateReportMutationVariables
  >(UpdateReportDocument, options)
}
export type UpdateReportMutationHookResult = ReturnType<
  typeof useUpdateReportMutation
>
export type UpdateReportMutationResult =
  Apollo.MutationResult<UpdateReportMutation>
export type UpdateReportMutationOptions = Apollo.BaseMutationOptions<
  UpdateReportMutation,
  UpdateReportMutationVariables
>
