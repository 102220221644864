import * as Types from '../../generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type UserNewRolesQueryVariables = Types.Exact<{ [key: string]: never }>

export type UserNewRolesQuery = {
  __typename?: 'Query'
  roles: Array<{ __typename?: 'Role'; id: string; name: string }>
}

export type UserNewCheckUserQueryVariables = Types.Exact<{
  user: Types.Scalars['String']
}>

export type UserNewCheckUserQuery = {
  __typename?: 'Query'
  users: Array<{ __typename?: 'User'; id: string }>
}

export type CreateUserMutationVariables = Types.Exact<{
  user: Types.Scalars['String']
  role: Types.Scalars['ID']
}>

export type CreateUserMutation = {
  __typename?: 'Mutation'
  createUser: {
    __typename?: 'CreateUserPayload'
    data: { __typename?: 'User'; id: string }
  }
}

export const UserNewRolesDocument = gql`
  query UserNewRoles {
    roles {
      id
      name
    }
  }
`

/**
 * __useUserNewRolesQuery__
 *
 * To run a query within a React component, call `useUserNewRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNewRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNewRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserNewRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserNewRolesQuery,
    UserNewRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserNewRolesQuery, UserNewRolesQueryVariables>(
    UserNewRolesDocument,
    options
  )
}
export function useUserNewRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserNewRolesQuery,
    UserNewRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserNewRolesQuery, UserNewRolesQueryVariables>(
    UserNewRolesDocument,
    options
  )
}
export type UserNewRolesQueryHookResult = ReturnType<
  typeof useUserNewRolesQuery
>
export type UserNewRolesLazyQueryHookResult = ReturnType<
  typeof useUserNewRolesLazyQuery
>
export type UserNewRolesQueryResult = Apollo.QueryResult<
  UserNewRolesQuery,
  UserNewRolesQueryVariables
>
export const UserNewCheckUserDocument = gql`
  query UserNewCheckUser($user: String!) {
    users(filter: { name: { _eq: $user } }) {
      id
    }
  }
`

/**
 * __useUserNewCheckUserQuery__
 *
 * To run a query within a React component, call `useUserNewCheckUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNewCheckUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNewCheckUserQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUserNewCheckUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserNewCheckUserQuery,
    UserNewCheckUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserNewCheckUserQuery, UserNewCheckUserQueryVariables>(
    UserNewCheckUserDocument,
    options
  )
}
export function useUserNewCheckUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserNewCheckUserQuery,
    UserNewCheckUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UserNewCheckUserQuery,
    UserNewCheckUserQueryVariables
  >(UserNewCheckUserDocument, options)
}
export type UserNewCheckUserQueryHookResult = ReturnType<
  typeof useUserNewCheckUserQuery
>
export type UserNewCheckUserLazyQueryHookResult = ReturnType<
  typeof useUserNewCheckUserLazyQuery
>
export type UserNewCheckUserQueryResult = Apollo.QueryResult<
  UserNewCheckUserQuery,
  UserNewCheckUserQueryVariables
>
export const CreateUserDocument = gql`
  mutation CreateUser($user: String!, $role: ID!) {
    createUser(input: { user: $user, role: $role }) {
      data {
        id
      }
    }
  }
`
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserMutation,
    CreateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options
  )
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>
