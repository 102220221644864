import { ColorMode, extendTheme, ThemeConfig } from '@chakra-ui/react'
import { Dict } from '@chakra-ui/utils'

const getInitialColorMode = (): ColorMode => {
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches
  const storedColorMode = localStorage.getItem('chakra-ui-color-mode')

  if (storedColorMode) {
    if (storedColorMode === 'light' || storedColorMode === 'dark') {
      return storedColorMode
    }
  }

  return prefersDarkMode ? 'dark' : 'light'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTheme = (): Dict<any> => {
  const config: ThemeConfig = {
    initialColorMode: getInitialColorMode(),
  }

  const theme = extendTheme({ config })

  return theme
}
