export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Upload: any
}

export type Account = {
  __typename?: 'Account'
  id: Scalars['ID']
  name: Scalars['String']
  user: Array<User>
}

export type Activity = {
  __typename?: 'Activity'
  data: ActivityData
  id: Scalars['ID']
  report: Report
  timestamp: Scalars['Int']
  user?: Maybe<Scalars['String']>
}

export type ActivityData = Comment | State

export enum ActivityType {
  Activated = 'ACTIVATED',
  Closed = 'CLOSED',
  Commented = 'COMMENTED',
}

export type Comment = {
  __typename?: 'Comment'
  comment: Scalars['String']
  id: Scalars['ID']
}

export type CompareBooleanInput = {
  _eq?: InputMaybe<Scalars['Boolean']>
  _gt?: InputMaybe<Scalars['Boolean']>
  _gte?: InputMaybe<Scalars['Boolean']>
  _in?: InputMaybe<Scalars['Boolean']>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['Boolean']>
  _lte?: InputMaybe<Scalars['Boolean']>
  _neq?: InputMaybe<Scalars['Boolean']>
  _nin?: InputMaybe<Scalars['Boolean']>
}

export type CompareIdInput = {
  _eq?: InputMaybe<Scalars['ID']>
  _gt?: InputMaybe<Scalars['ID']>
  _gte?: InputMaybe<Scalars['ID']>
  _in?: InputMaybe<Scalars['ID']>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['ID']>
  _lte?: InputMaybe<Scalars['ID']>
  _neq?: InputMaybe<Scalars['ID']>
  _nin?: InputMaybe<Scalars['ID']>
}

export type CompareStringInput = {
  _eq?: InputMaybe<Scalars['String']>
  _gt?: InputMaybe<Scalars['String']>
  _gte?: InputMaybe<Scalars['String']>
  _in?: InputMaybe<Scalars['String']>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _like?: InputMaybe<Scalars['String']>
  _lt?: InputMaybe<Scalars['String']>
  _lte?: InputMaybe<Scalars['String']>
  _neq?: InputMaybe<Scalars['String']>
  _nin?: InputMaybe<Scalars['String']>
  _nlike?: InputMaybe<Scalars['String']>
}

export type CreateCommentInput = {
  comment: Scalars['String']
  report: Scalars['ID']
}

export type CreateCommentPayload = {
  __typename?: 'CreateCommentPayload'
  data?: Maybe<Comment>
}

export type CreateReportInput = {
  aircraft: Scalars['String']
  airspace: Scalars['String']
  anonym: Scalars['Boolean']
  approach: Scalars['String']
  attachments?: InputMaybe<Array<Scalars['Upload']>>
  damageOnAircraft: Scalars['String']
  damageOnThird: Scalars['String']
  damageReport: Scalars['String']
  dangerousGoods: Scalars['String']
  date: Scalars['String']
  departure: Scalars['String']
  destination: Scalars['String']
  fatalCrew: Scalars['String']
  fatalOthers: Scalars['String']
  fatalPax: Scalars['String']
  flightnumber: Scalars['String']
  flightphase: Scalars['String']
  flightplan: Scalars['String']
  flightrules: Scalars['String']
  flighttype: Scalars['String']
  hasDangerousGoods: Scalars['Boolean']
  ias: Scalars['String']
  icing: Scalars['String']
  light: Scalars['String']
  location: Scalars['String']
  minorCrew: Scalars['String']
  minorOthers: Scalars['String']
  minorPax: Scalars['String']
  noneCrew: Scalars['String']
  noneOthers: Scalars['String']
  nonePax: Scalars['String']
  numOfCrew: Scalars['String']
  numOfPax: Scalars['String']
  oat: Scalars['String']
  pilot: Scalars['String']
  report: Scalars['String']
  runway: Scalars['String']
  seriousCrew: Scalars['String']
  seriousOthers: Scalars['String']
  seriousPax: Scalars['String']
  time: Scalars['String']
  turbulences: Scalars['String']
  weather: Scalars['String']
  weatherIntensity: Scalars['String']
  wind: Scalars['String']
  windDirection: Scalars['String']
  windSpeed: Scalars['String']
}

export type CreateReportPayload = {
  __typename?: 'CreateReportPayload'
  data?: Maybe<Report>
}

export type CreateUserInput = {
  role: Scalars['ID']
  user: Scalars['String']
}

export type CreateUserPayload = {
  __typename?: 'CreateUserPayload'
  data: User
}

export type DangerousGoods = {
  __typename?: 'DangerousGoods'
  checked: Scalars['Boolean']
  content: Scalars['String']
}

export type File = {
  __typename?: 'File'
  id: Scalars['ID']
  name: Scalars['String']
  path: Scalars['String']
}

export type FilterUserInput = {
  account?: InputMaybe<CompareStringInput>
  active?: InputMaybe<CompareBooleanInput>
  and?: InputMaybe<Array<InputMaybe<FilterUserInput>>>
  id?: InputMaybe<CompareIdInput>
  name?: InputMaybe<CompareStringInput>
  not?: InputMaybe<Array<InputMaybe<FilterUserInput>>>
  or?: InputMaybe<Array<InputMaybe<FilterUserInput>>>
  role?: InputMaybe<CompareIdInput>
}

export type Mutation = {
  __typename?: 'Mutation'
  createComment: CreateCommentPayload
  createReport: CreateReportPayload
  createUser: CreateUserPayload
  updateReport: UpdateReportPayload
}

export type MutationCreateCommentArgs = {
  input: CreateCommentInput
}

export type MutationCreateReportArgs = {
  input: CreateReportInput
}

export type MutationCreateUserArgs = {
  input: CreateUserInput
}

export type MutationUpdateReportArgs = {
  input: UpdateReportInput
}

export type Query = {
  __typename?: 'Query'
  report?: Maybe<Report>
  reports: Array<Report>
  roles: Array<Role>
  users: Array<User>
}

export type QueryReportArgs = {
  id: Scalars['ID']
}

export type QueryUsersArgs = {
  filter?: InputMaybe<FilterUserInput>
}

export type Report = {
  __typename?: 'Report'
  activities: Array<Activity>
  aircraft: Scalars['String']
  airspace: Scalars['String']
  approach: Scalars['String']
  attachments: Array<File>
  created: Scalars['Int']
  damageOnAircraft: Scalars['String']
  damageOnThird: Scalars['String']
  damageReport: Scalars['String']
  dangerousGoods: DangerousGoods
  date: Scalars['String']
  departure: Scalars['String']
  destination: Scalars['String']
  fatalCrew: Scalars['String']
  fatalOthers: Scalars['String']
  fatalPax: Scalars['String']
  flightnumber: Scalars['String']
  flightphase: Scalars['String']
  flightplan: Scalars['String']
  flightrules: Scalars['String']
  flighttype: Scalars['String']
  ias: Scalars['String']
  icing: Scalars['String']
  id: Scalars['ID']
  light: Scalars['String']
  location: Scalars['String']
  minorCrew: Scalars['String']
  minorOthers: Scalars['String']
  minorPax: Scalars['String']
  noneCrew: Scalars['String']
  noneOthers: Scalars['String']
  nonePax: Scalars['String']
  numOfAttachments: Scalars['Int']
  numOfComments: Scalars['Int']
  numOfCrew: Scalars['String']
  numOfPax: Scalars['String']
  oat: Scalars['String']
  pilot: Scalars['String']
  report: Scalars['String']
  runway: Scalars['String']
  seriousCrew: Scalars['String']
  seriousOthers: Scalars['String']
  seriousPax: Scalars['String']
  state: StateType
  time: Scalars['String']
  turbulences: Scalars['String']
  user?: Maybe<Scalars['String']>
  weather: Scalars['String']
  weatherIntensity: Scalars['String']
  wind: Scalars['String']
  windDirection: Scalars['String']
  windSpeed: Scalars['String']
}

export type Role = {
  __typename?: 'Role'
  admin: Scalars['Boolean']
  id: Scalars['ID']
  name: Scalars['String']
  readReport: Scalars['Boolean']
  writeReport: Scalars['Boolean']
}

export type State = {
  __typename?: 'State'
  id: Scalars['ID']
  state: StateType
}

export enum StateType {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
}

export type UpdateReportInput = {
  id: Scalars['ID']
  state: StateType
}

export type UpdateReportPayload = {
  __typename?: 'UpdateReportPayload'
  data: Report
}

export type User = {
  __typename?: 'User'
  accounts: Array<Account>
  active?: Maybe<Scalars['Boolean']>
  activities: Array<Activity>
  id: Scalars['ID']
  name: Scalars['String']
  reports: Array<Report>
  role: Role
}
