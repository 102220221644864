import { Button, IconButton } from '@chakra-ui/button'
import { Checkbox } from '@chakra-ui/checkbox'
import { useColorMode } from '@chakra-ui/color-mode'
import { FormControl, FormLabel } from '@chakra-ui/form-control'
import Icon from '@chakra-ui/icon'
import { Image } from '@chakra-ui/image'
import { Input, InputGroup, InputRightElement } from '@chakra-ui/input'
import { Box, Heading, Text, VStack } from '@chakra-ui/layout'
import React, { useState } from 'react'
import { FaMoon, FaRegEye, FaRegEyeSlash, FaSun } from 'react-icons/fa'
import { Navigate, NavLink, useLocation } from 'react-router-dom'

import logo from '../../assets/logo.svg'
import { useAuth } from '../AuthProvider'

const hasOwnProperty = <Y extends PropertyKey>(
  obj: unknown,
  prop: Y
): obj is unknown & Record<Y, unknown> => {
  if (obj && typeof obj === 'object') {
    return obj.hasOwnProperty(prop)
  }

  return false
}

type FormState = {
  user: string
  password: string
  remember: boolean
  error: boolean
  submitting: boolean
  showPassword: boolean
}

const Login: React.FC = () => {
  const { colorMode, toggleColorMode } = useColorMode()
  const location = useLocation()
  const auth = useAuth()

  let from = '/'

  if (hasOwnProperty(location.state, 'from')) {
    if (hasOwnProperty(location.state.from, 'pathname')) {
      from = location.state.from.pathname as string
    }
  }

  const [form, setForm] = useState<FormState>({
    user: '',
    password: '',
    remember: true,
    error: false,
    submitting: false,
    showPassword: false,
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const field = event.currentTarget.id
    const value =
      event.currentTarget.type === 'checkbox'
        ? event.currentTarget.checked
        : event.currentTarget.value

    setForm((prev) => ({ ...prev, [field]: value }))
  }

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault()
    setForm((prev) => ({ ...prev, submitting: true }))
    try {
      await auth.signin(form.user, form.password, form.remember)
    } catch {
      setForm((prev) => ({ ...prev, error: true, submitting: false }))
    }
  }

  const handleToggle = () => {
    setForm((prev) => ({ ...prev, showPassword: !prev.showPassword }))
  }

  if (auth.user) {
    return <Navigate to={from} />
  }

  return (
    <main>
      <Box display="flex" minHeight="100vh">
        <Box
          display="flex"
          flex={{ base: 1, lg: 'none' }}
          flexDirection="column"
          px={{ base: 4, sm: 6, lg: 20, xl: 24 }}
          py={{ base: 12 }}
        >
          <Box mx="auto" width={{ base: 'full', lg: '96' }} maxWidth="sm">
            <Box>
              <Image
                alt="Occurrency Report"
                src={logo}
                height={12}
                width="auto"
              />
              <Heading as="h2" fontWeight="extrabold" mt={6} size="lg">
                Sign in to your account
              </Heading>
              <Text as="p" fontSize="sm" mt={2}>
                Or{' '}
                <Box
                  as={NavLink}
                  color="blue.600"
                  to="/register"
                  _hover={{ color: 'blue.500' }}
                >
                  register here
                </Box>
              </Text>
            </Box>
            <Box mt={6}>
              <form onSubmit={handleLogin}>
                <VStack spacing={6}>
                  <FormControl id="user">
                    <FormLabel>User</FormLabel>
                    <Input
                      onChange={handleChange}
                      type="email"
                      value={form.user}
                    />
                  </FormControl>
                  <FormControl id="password">
                    <FormLabel>Password</FormLabel>
                    <InputGroup>
                      <Input
                        onChange={handleChange}
                        type={form.showPassword ? 'text' : 'password'}
                        value={form.password}
                      />
                      <InputRightElement>
                        <IconButton
                          aria-label={form.showPassword ? 'Hide' : 'Show'}
                          icon={
                            <Icon
                              as={form.showPassword ? FaRegEyeSlash : FaRegEye}
                            />
                          }
                          onClick={handleToggle}
                          variant="ghost"
                        />
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                    width="full"
                  >
                    <Checkbox
                      id="remember"
                      isChecked={form.remember}
                      onChange={handleChange}
                    >
                      Remember me
                    </Checkbox>
                    <Box
                      as={NavLink}
                      color="blue.600"
                      fontSize="medium"
                      to="/password"
                      _hover={{ color: 'blue.500' }}
                    >
                      Forgot your password?
                    </Box>
                  </Box>
                  <Button
                    colorScheme="blue"
                    isLoading={form.submitting}
                    loadingText="Signin in"
                    onClick={handleLogin}
                    type="submit"
                    width="full"
                  >
                    Sign in
                  </Button>
                  {form.error && (
                    <Box color="red.600" fontWeight="medium">
                      Es ist ein Fehler aufgetreten
                    </Box>
                  )}
                </VStack>
              </form>
            </Box>
          </Box>
        </Box>
        <Box
          display={{ base: 'none', lg: 'block' }}
          flex="1"
          position="relative"
        >
          <Image
            height="100%"
            objectFit="cover"
            position="absolute"
            inset="0"
            src="https://images.unsplash.com/photo-1556388158-158ea5ccacbd?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80"
            width="100%"
          />
        </Box>
      </Box>
      <Box bottom={12} left={12} position="fixed">
        <Button onClick={toggleColorMode}>
          <Icon
            aria-label="Farbschema ändern"
            as={colorMode === 'light' ? FaMoon : FaSun}
          />
        </Button>
      </Box>
    </main>
  )
}

export default Login
