import { ChakraProvider } from '@chakra-ui/react'
import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { getTheme } from '../../theme'
import AuthProvider from '../AuthProvider'
import ClientProvider from '../ClientProvider'
import Dashboard from '../Dashboard'
import Login from '../Login'
import NotFound from '../NotFound'
import ProtectedRoute from '../ProtectedRoute'
import ReportNew from '../ReportNew'
import ReportView from '../ReportView'
import UserEdit from '../UserEdit'
import UserNew from '../UserNew'
import Users from '../Users'
import UserSetPassword from '../UserSetPassword'

const App: React.FC = () => {
  return (
    <ChakraProvider theme={getTheme()}>
      <AuthProvider>
        <ClientProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Navigate replace to="/dashboard" />} />
              <Route path="/login" element={<Login />} />
              <Route path="/userConfirm/:id" element={<UserSetPassword />} />
              <Route element={<ProtectedRoute />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/report/new" element={<ReportNew />} />
                <Route path="/report/:id" element={<ReportView />} />
                <Route path="/users" element={<Users />} />
                <Route path="/users/new" element={<UserNew />} />
                <Route path="/users/:id" element={<UserEdit />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </ClientProvider>
      </AuthProvider>
    </ChakraProvider>
  )
}

export default App
