import { Button } from '@chakra-ui/button'
import { Checkbox } from '@chakra-ui/checkbox'
import { FormControl, FormLabel } from '@chakra-ui/form-control'
import Icon from '@chakra-ui/icon'
import { Input } from '@chakra-ui/input'
import {
  Divider,
  Grid,
  GridItem,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/layout'
import { useBreakpointValue } from '@chakra-ui/media-query'
import { Select } from '@chakra-ui/select'
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table'
import { Textarea } from '@chakra-ui/textarea'
import { useToast } from '@chakra-ui/toast'
import moment from 'moment'
import React, { useState } from 'react'
import {
  FaCarCrash,
  FaCloudSun,
  FaExchangeAlt,
  FaFlag,
  FaNotesMedical,
  FaPaperclip,
  FaPaperPlane,
  FaPlane,
  FaPlus,
  FaRegCalendarCheck,
  FaRegEdit,
  FaTimes,
} from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import PageTitle from '../PageTitle'
import { useCreateReportMutation } from './createReport.generated'
import Fieldset from './Fieldset'
import Legend from './Legend'

export const dateFormat = 'ddd, DD. MMMM YYYY, HH:mm UTC'

type FormFields = {
  location: string
  date: string
  time: string
  aircraft: string
  flightnumber: string
  departure: string
  destination: string
  flightplan: string
  pilot: string
  numOfCrew: string
  numOfPax: string
  dangerousGoods: string
  dangerousGoodsCheck: boolean
  flighttype: string
  flightrules: string
  ias: string
  flightphase: string
  light: string
  approach: string
  runway: string
  airspace: string
  weather: string
  weatherIntensity: string
  oat: string
  wind: string
  windDirection: string
  windSpeed: string
  turbulences: string
  icing: string
  report: string
  fatalCrew: string
  fatalPax: string
  fatalOthers: string
  seriousCrew: string
  seriousPax: string
  seriousOthers: string
  minorCrew: string
  minorPax: string
  minorOthers: string
  noneCrew: string
  nonePax: string
  noneOthers: string
  damageOnAircraft: string
  damageOnThird: string
  damageReport: string
  attachments: (File | null)[]
  anonym: boolean
}

const ReportNew: React.FC = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const medicalConsequencesTableSize = useBreakpointValue({
    base: 'sm',
    md: 'md',
  })
  const medicalConsequencesTablePaddingX = useBreakpointValue({
    base: 2,
    md: 6,
  })
  const medicalConsequencesTablePaddingY = useBreakpointValue({
    base: 2,
    md: 4,
  })

  const [createReport] = useCreateReportMutation()

  const [formValues, setFormValues] = useState<FormFields>({
    location: '',
    date: '',
    time: '',
    aircraft: '',
    flightnumber: '',
    departure: '',
    destination: '',
    flightplan: '',
    pilot: '',
    numOfCrew: '',
    numOfPax: '',
    dangerousGoods: '',
    dangerousGoodsCheck: false,
    flighttype: '',
    flightrules: '',
    ias: '',
    flightphase: '',
    light: '',
    approach: '',
    runway: '',
    airspace: '',
    weather: '',
    weatherIntensity: '',
    oat: '',
    wind: '',
    windDirection: '',
    windSpeed: '',
    turbulences: '',
    icing: '',
    report: `* WHAT HAPPENED?


* WHY DID IT HAPPEN?


* HOW WAS IT FIXED?`,
    fatalCrew: '',
    fatalPax: '',
    fatalOthers: '',
    seriousCrew: '',
    seriousPax: '',
    seriousOthers: '',
    minorCrew: '',
    minorPax: '',
    minorOthers: '',
    noneCrew: '',
    nonePax: '',
    noneOthers: '',
    damageOnAircraft: '',
    damageOnThird: '',
    damageReport: '',
    attachments: [null],
    anonym: false,
  })

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const field = event.currentTarget.id
    const value =
      event.currentTarget.type === 'checkbox'
        ? (event.currentTarget as HTMLInputElement).checked
        : event.currentTarget.value

    setFormValues((prev) => ({ ...prev, [field]: value }))
  }

  const handleFileSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const file = event.currentTarget.files && event.currentTarget.files[0]
    setFormValues((prev) => ({
      ...prev,
      attachments: prev.attachments.map((currentAttachment, currentIndex) => {
        if (currentIndex === index) {
          return file
        }

        return currentAttachment
      }),
    }))
  }

  const handleAddMoreAttachment = () => {
    setFormValues((prev) => ({
      ...prev,
      attachments: [...prev.attachments, null],
    }))
  }

  const handleCancel = () => {
    navigate(-1)
  }

  const handleSubmit = async () => {
    try {
      await createReport({
        variables: {
          location: formValues.location,
          date: formValues.date,
          time: formValues.time,
          aircraft: formValues.aircraft,
          flightnumber: formValues.flightnumber,
          departure: formValues.departure,
          destination: formValues.destination,
          flightplan: formValues.flightplan,
          pilot: formValues.pilot,
          numOfCrew: formValues.numOfCrew,
          numOfPax: formValues.numOfPax,
          hasDangerousGoods: formValues.dangerousGoodsCheck,
          dangerousGoods: formValues.dangerousGoods,
          flighttype: formValues.flighttype,
          flightrules: formValues.flightrules,
          ias: formValues.ias,
          flightphase: formValues.flightphase,
          light: formValues.light,
          approach: formValues.approach,
          runway: formValues.runway,
          airspace: formValues.airspace,
          weather: formValues.weather,
          weatherIntensity: formValues.weatherIntensity,
          oat: formValues.oat,
          wind: formValues.wind,
          windDirection: formValues.windDirection,
          windSpeed: formValues.windSpeed,
          turbulences: formValues.turbulences,
          icing: formValues.icing,
          report: formValues.report,
          fatalCrew: formValues.fatalCrew,
          fatalPax: formValues.fatalPax,
          fatalOthers: formValues.fatalOthers,
          seriousCrew: formValues.seriousCrew,
          seriousPax: formValues.seriousPax,
          seriousOthers: formValues.seriousOthers,
          minorCrew: formValues.minorCrew,
          minorPax: formValues.minorPax,
          minorOthers: formValues.minorOthers,
          noneCrew: formValues.noneCrew,
          nonePax: formValues.nonePax,
          noneOthers: formValues.noneOthers,
          damageOnAircraft: formValues.damageOnAircraft,
          damageOnThird: formValues.damageOnThird,
          damageReport: formValues.damageReport,
          anonym: formValues.anonym,
          attachments: formValues.attachments.filter(
            (attachment) => attachment !== null
          ),
        },
      })
      toast({
        description: 'Your report was successfully submitted.',
        duration: 9000,
        isClosable: true,
        status: 'success',
        title: 'Report submitted',
      })
      navigate('/dashboard')
    } catch {
      toast({
        description: 'Please try again',
        duration: 9000,
        isClosable: true,
        status: 'error',
        title: 'An error occurred',
      })
    }
  }

  return (
    <>
      <PageTitle
        icon={FaRegEdit}
        subtitle={
          <Text fontSize="medium">{moment().utc().format(dateFormat)}</Text>
        }
        title="Flight Safety Report"
      />
      <Divider />
      <form>
        <Fieldset>
          <Legend icon={FaRegCalendarCheck}>General data</Legend>
          <Grid
            templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
            gap={8}
          >
            <FormControl id="location">
              <FormLabel>Location</FormLabel>
              <Input
                autoComplete="off"
                onChange={handleChange}
                placeholder="Location name"
                value={formValues.location}
              />
            </FormControl>

            <FormControl id="date">
              <FormLabel>Date</FormLabel>
              <Input
                autoComplete="off"
                onChange={handleChange}
                placeholder="Date"
                value={formValues.date}
              />
            </FormControl>

            <FormControl id="time">
              <FormLabel>Time (UTC)</FormLabel>
              <Input
                autoComplete="off"
                onChange={handleChange}
                placeholder={`Time (${moment.utc().format('HH:mm')})`}
                value={formValues.time}
              />
            </FormControl>

            <FormControl id="aircraft">
              <FormLabel>Aircraft</FormLabel>
              <Input
                autoComplete="off"
                onChange={handleChange}
                placeholder="Aircraft"
                value={formValues.aircraft}
              />
            </FormControl>

            <FormControl id="flightnumber">
              <FormLabel>Flight number</FormLabel>
              <Input
                autoComplete="off"
                onChange={handleChange}
                placeholder="Flight number"
                value={formValues.flightnumber}
              />
            </FormControl>
          </Grid>
        </Fieldset>

        <Fieldset>
          <Legend icon={FaExchangeAlt}>Flight information</Legend>
          <Grid
            templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
            gap={8}
          >
            <FormControl id="departure">
              <FormLabel>Departure</FormLabel>
              <Input
                autoComplete="off"
                onChange={handleChange}
                placeholder="Departure"
                value={formValues.departure}
              />
            </FormControl>

            <FormControl id="destination">
              <FormLabel>Destination</FormLabel>
              <Input
                autoComplete="off"
                onChange={handleChange}
                placeholder="Destination"
                value={formValues.destination}
              />
            </FormControl>

            <FormControl id="flightplan">
              <FormLabel>Flight plan</FormLabel>
              <Input
                autoComplete="off"
                onChange={handleChange}
                placeholder="Flight plan"
                value={formValues.flightplan}
              />
            </FormControl>

            <FormControl id="pilot">
              <FormLabel>Pilot in command</FormLabel>
              <Input
                autoComplete="off"
                onChange={handleChange}
                placeholder="Pilot in command"
                value={formValues.pilot}
              />
            </FormControl>

            <FormControl id="numOfCrew">
              <FormLabel>Number of crew members</FormLabel>
              <Input
                autoComplete="off"
                onChange={handleChange}
                placeholder="Number of crew members"
                value={formValues.numOfCrew}
              />
            </FormControl>

            <FormControl id="numOfPax">
              <FormLabel>Number of passenger</FormLabel>
              <Input
                autoComplete="off"
                onChange={handleChange}
                placeholder="Number of passenger"
                value={formValues.numOfPax}
              />
            </FormControl>

            <FormControl id="dangerousGoods">
              <HStack spacing={2}>
                <FormLabel>Dangerous Goods</FormLabel>
                <Checkbox
                  aria-label="Dangerous Goods Toggle"
                  id="dangerousGoodsCheck"
                  pb={2}
                  onChange={handleChange}
                  checked={formValues.dangerousGoodsCheck}
                />
              </HStack>
              <Textarea
                autoComplete="off"
                isDisabled={!formValues.dangerousGoodsCheck}
                onChange={handleChange}
                placeholder="Dangerous Goods"
                rows={1}
                value={formValues.dangerousGoods}
              />
            </FormControl>
          </Grid>
        </Fieldset>

        <Fieldset>
          <Legend icon={FaPlane}>Flight conditions</Legend>
          <Grid
            templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
            gap={8}
          >
            <FormControl id="flighttype">
              <FormLabel>Type of flight</FormLabel>
              <Select
                onChange={handleChange}
                placeholder="Select..."
                value={formValues.flighttype}
              >
                <option value="Cargo">Cargo</option>
                <option value="Charter">Charter</option>
                <option value="Domestic">Domestic</option>
                <option value="Ferry">Ferry</option>
                <option value="International">International</option>
                <option value="Other">Other</option>
                <option value="Passenger">Passenger</option>
                <option value="Regular">Scheduled</option>
                <option value="Training">Training</option>
              </Select>
            </FormControl>

            <FormControl id="flightrules">
              <FormLabel>Flight rules</FormLabel>
              <Select
                onChange={handleChange}
                placeholder="Select..."
                value={formValues.flightrules}
              >
                <option value="IFR">IFR</option>
                <option value="VFR">VFR</option>
              </Select>
            </FormControl>

            <FormControl id="ias">
              <FormLabel>IAS (kt)</FormLabel>
              <Input
                autoComplete="off"
                onChange={handleChange}
                placeholder="IAS (kt)"
                value={formValues.ias}
              />
            </FormControl>

            <FormControl id="flightphase">
              <FormLabel>Phase of flight</FormLabel>
              <Select
                onChange={handleChange}
                placeholder="Select..."
                value={formValues.flightphase}
              >
                <option value="Standing">Standing</option>
                <option value="Taxi">Taxi</option>
                <option value="Take off">Take off</option>
                <option value="Missed approach">Missed approach</option>
                <option value="Climb">Climb</option>
                <option value="En route">En route</option>
                <option value="Flight level change">Flight level change</option>
                <option value="Descent">Descent</option>
                <option value="Approach">Approach</option>
                <option value="Landing">Landing</option>
                <option value="Taxi after landing">
                  Taxiing after landing
                </option>
                <option value="Others">Others</option>
              </Select>
            </FormControl>

            <FormControl id="light">
              <FormLabel>Light conditions</FormLabel>
              <Select
                onChange={handleChange}
                placeholder="Select..."
                value={formValues.light}
              >
                <option value="Daylight">Daylight</option>
                <option value="Dawn">Dawn</option>
                <option value="Night (dark)">Night (dark)</option>
                <option value="Night (moonlight)">Night (moonlight)</option>
                <option value="Dusk / Twilight">Dusk / Twilight</option>
                <option value="unknown">unknown</option>
              </Select>
            </FormControl>

            <GridItem colStart={1}>
              <FormControl id="approach">
                <FormLabel>Approach type</FormLabel>
                <Select
                  onChange={handleChange}
                  placeholder="Select..."
                  value={formValues.approach}
                >
                  <option value="ILS">ILS</option>
                  <option value="RNAV">RNAV</option>
                  <option value="Visual">Visual</option>
                  <option value="VOR">VOR</option>
                  <option value="NDB">NDB</option>
                  <option value="PAR">PAR</option>
                  <option value="LOC/DME">LOC/DME</option>
                </Select>
              </FormControl>
            </GridItem>

            <FormControl id="runway">
              <FormLabel>Runway condition</FormLabel>
              <Select
                onChange={handleChange}
                placeholder="Select..."
                value={formValues.runway}
              >
                <option value="Dry">Dry</option>
                <option value="Wet">Wet</option>
                <option value="Ice">Ice</option>
                <option value="Snow">Snow</option>
                <option value="Slush">Slush</option>
              </Select>
            </FormControl>

            <FormControl id="airspace">
              <FormLabel>Airspace</FormLabel>
              <Select
                onChange={handleChange}
                placeholder="Select..."
                value={formValues.airspace}
              >
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
                <option value="D">D</option>
                <option value="E">E</option>
                <option value="F">F</option>
                <option value="G">G</option>
                <option value="G+">G+</option>
                <option value="not specified">not specified</option>
              </Select>
            </FormControl>
          </Grid>
        </Fieldset>

        <Fieldset>
          <Legend icon={FaCloudSun}>Weather conditions</Legend>
          <Grid
            templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
            gap={8}
          >
            <FormControl id="weather">
              <FormLabel>Weather</FormLabel>
              <Select
                onChange={handleChange}
                placeholder="Select..."
                value={formValues.weather}
              >
                <option value="CAVOK">CAVOK</option>
                <option value="Mist">Mist</option>
                <option value="Fog">Fog</option>
                <option value="Smoke">Smoke</option>
                <option value="Haze">Haze</option>
                <option value="Sand">Sand</option>
                <option value="Volcanic Ash">Volcanic Ash</option>
                <option value="Hail">Hail</option>
                <option value="Ice">Ice</option>
                <option value="Rain">Rain</option>
                <option value="Snow">Snow</option>
                <option value="Turbulence">Turbulence</option>
                <option value="Wind shear">Wind shear</option>
                <option value="Thunderstorm">Thunderstorm</option>
                <option value="Mountain waves">Mountain waves</option>
                <option value="Tropical Cyclone">Tropical Cyclone</option>
                <option value="Clouds">Clouds</option>
                <option value="unknown">unknown</option>
              </Select>
            </FormControl>

            <FormControl id="weatherIntensity">
              <FormLabel>Intensity</FormLabel>
              <Select
                onChange={handleChange}
                placeholder="Select..."
                value={formValues.weatherIntensity}
              >
                <option value="NIL">NIL</option>
                <option value="light">light</option>
                <option value="moderate">moderate</option>
                <option value="heavy">heavy</option>
                <option value="severe">severe</option>
              </Select>
            </FormControl>

            <FormControl id="oat">
              <FormLabel>OAT (°C)</FormLabel>
              <Input
                autoComplete="off"
                onChange={handleChange}
                placeholder="OAT (°C)"
                value={formValues.oat}
              />
            </FormControl>

            <FormControl id="wind">
              <FormLabel>Wind</FormLabel>
              <Select
                onChange={handleChange}
                placeholder="Select..."
                value={formValues.wind}
              >
                <option value="steady">steady</option>
                <option value="gusty">gusty</option>
                <option value="variable">variable</option>
              </Select>
            </FormControl>

            <FormControl id="windDirection">
              <FormLabel>Direction</FormLabel>
              <Input
                autoComplete="off"
                onChange={handleChange}
                placeholder="Direction"
                value={formValues.windDirection}
              />
            </FormControl>

            <FormControl id="windSpeed">
              <FormLabel>Speed (kt)</FormLabel>
              <Input
                autoComplete="off"
                onChange={handleChange}
                placeholder="Speed (kt)"
                value={formValues.windSpeed}
              />
            </FormControl>

            <FormControl id="turbulences">
              <FormLabel>Turbulences</FormLabel>
              <Select
                onChange={handleChange}
                placeholder="Select..."
                value={formValues.turbulences}
              >
                <option value="light">light</option>
                <option value="moderate">moderate</option>
                <option value="severe">severe</option>
                <option value="none">none</option>
              </Select>
            </FormControl>

            <FormControl id="icing">
              <FormLabel>Icing</FormLabel>
              <Select
                onChange={handleChange}
                placeholder="Select..."
                value={formValues.icing}
              >
                <option value="light">light</option>
                <option value="moderate">moderate</option>
                <option value="severe">severe</option>
                <option value="none">none</option>
              </Select>
            </FormControl>
          </Grid>
        </Fieldset>

        <Fieldset>
          <Legend icon={FaFlag}>Course of events</Legend>
          <Grid templateColumns={{ base: 'repeat(1, 1fr)' }} gap={8}>
            <FormControl id="report">
              <FormLabel>Report</FormLabel>
              <Textarea
                autoComplete="off"
                onChange={handleChange}
                placeholder="Report"
                rows={8}
                value={formValues.report}
              />
            </FormControl>
          </Grid>
        </Fieldset>

        <Fieldset>
          <Legend icon={FaNotesMedical}>Consequences</Legend>
          <Table
            aria-label="Consequences"
            size={medicalConsequencesTableSize}
            variant="simple"
          >
            <Thead>
              <Tr>
                <Th
                  paddingX={medicalConsequencesTablePaddingX}
                  paddingY={medicalConsequencesTablePaddingY}
                >
                  &nbsp;
                </Th>
                <Th
                  paddingX={medicalConsequencesTablePaddingX}
                  paddingY={medicalConsequencesTablePaddingY}
                >
                  Crew
                </Th>
                <Th
                  paddingX={medicalConsequencesTablePaddingX}
                  paddingY={medicalConsequencesTablePaddingY}
                >
                  Passenger
                </Th>
                <Th
                  paddingX={medicalConsequencesTablePaddingX}
                  paddingY={medicalConsequencesTablePaddingY}
                >
                  Others
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td
                  paddingX={medicalConsequencesTablePaddingX}
                  paddingY={medicalConsequencesTablePaddingY}
                >
                  fatal
                </Td>
                <Td
                  paddingX={medicalConsequencesTablePaddingX}
                  paddingY={medicalConsequencesTablePaddingY}
                >
                  <Input
                    aria-label="fatal crew"
                    autoComplete="off"
                    id="fatalCrew"
                    onChange={handleChange}
                    value={formValues.fatalCrew}
                  />
                </Td>
                <Td
                  paddingX={medicalConsequencesTablePaddingX}
                  paddingY={medicalConsequencesTablePaddingY}
                >
                  <Input
                    aria-label="fatal pax"
                    autoComplete="off"
                    id="fatalPax"
                    onChange={handleChange}
                    value={formValues.fatalPax}
                  />
                </Td>
                <Td
                  paddingX={medicalConsequencesTablePaddingX}
                  paddingY={medicalConsequencesTablePaddingY}
                >
                  <Input
                    aria-label="fatal others"
                    autoComplete="off"
                    id="fatalOthers"
                    onChange={handleChange}
                    value={formValues.fatalOthers}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td
                  paddingX={medicalConsequencesTablePaddingX}
                  paddingY={medicalConsequencesTablePaddingY}
                >
                  serious
                </Td>
                <Td
                  paddingX={medicalConsequencesTablePaddingX}
                  paddingY={medicalConsequencesTablePaddingY}
                >
                  <Input
                    aria-label="serious crew"
                    autoComplete="off"
                    id="seriousCrew"
                    onChange={handleChange}
                    value={formValues.seriousCrew}
                  />
                </Td>
                <Td
                  paddingX={medicalConsequencesTablePaddingX}
                  paddingY={medicalConsequencesTablePaddingY}
                >
                  <Input
                    aria-label="serious pax"
                    autoComplete="off"
                    id="seriousPax"
                    onChange={handleChange}
                    value={formValues.seriousPax}
                  />
                </Td>
                <Td
                  paddingX={medicalConsequencesTablePaddingX}
                  paddingY={medicalConsequencesTablePaddingY}
                >
                  <Input
                    aria-label="serious others"
                    autoComplete="off"
                    id="seriousOthers"
                    onChange={handleChange}
                    value={formValues.seriousOthers}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td
                  paddingX={medicalConsequencesTablePaddingX}
                  paddingY={medicalConsequencesTablePaddingY}
                >
                  minor
                </Td>
                <Td
                  paddingX={medicalConsequencesTablePaddingX}
                  paddingY={medicalConsequencesTablePaddingY}
                >
                  <Input
                    aria-label="minor crew"
                    autoComplete="off"
                    id="minorCrew"
                    onChange={handleChange}
                    value={formValues.minorCrew}
                  />
                </Td>
                <Td
                  paddingX={medicalConsequencesTablePaddingX}
                  paddingY={medicalConsequencesTablePaddingY}
                >
                  <Input
                    aria-label="minor pax"
                    autoComplete="off"
                    id="minorPax"
                    onChange={handleChange}
                    value={formValues.minorPax}
                  />
                </Td>
                <Td
                  paddingX={medicalConsequencesTablePaddingX}
                  paddingY={medicalConsequencesTablePaddingY}
                >
                  <Input
                    aria-label="minor others"
                    autoComplete="off"
                    id="minorOthers"
                    onChange={handleChange}
                    value={formValues.minorOthers}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td
                  paddingX={medicalConsequencesTablePaddingX}
                  paddingY={medicalConsequencesTablePaddingY}
                >
                  none
                </Td>
                <Td
                  paddingX={medicalConsequencesTablePaddingX}
                  paddingY={medicalConsequencesTablePaddingY}
                >
                  <Input
                    aria-label="none crew"
                    autoComplete="off"
                    id="noneCrew"
                    onChange={handleChange}
                    value={formValues.noneCrew}
                  />
                </Td>
                <Td paddingX={{ base: 2, md: 6 }} paddingY={{ base: 2, md: 4 }}>
                  <Input
                    aria-label="none pax"
                    autoComplete="off"
                    id="nonePax"
                    onChange={handleChange}
                    value={formValues.nonePax}
                  />
                </Td>
                <Td
                  paddingX={medicalConsequencesTablePaddingX}
                  paddingY={medicalConsequencesTablePaddingY}
                >
                  <Input
                    aria-label="none others"
                    autoComplete="off"
                    id="noneOthers"
                    onChange={handleChange}
                    value={formValues.noneOthers}
                  />
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Fieldset>

        <Fieldset>
          <Legend icon={FaCarCrash}>Damages</Legend>
          <Grid
            templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
            gap={8}
          >
            <FormControl id="damageOnAircraft">
              <FormLabel>Damage of aircraft</FormLabel>
              <Select
                onChange={handleChange}
                placeholder="Select..."
                value={formValues.damageOnAircraft}
              >
                <option value="destroyed">destroyed</option>
                <option value="substantial">substantial</option>
                <option value="minor">minor</option>
                <option value="no damage">no damage</option>
                <option value="unknown">unknown</option>
              </Select>
            </FormControl>

            <FormControl id="damageOnThird">
              <FormLabel>
                Third Party Damage{' '}
                <small>(Buildings, Vehicles, Plants, ...)</small>
              </FormLabel>
              <Input
                autoComplete="off"
                onChange={handleChange}
                value={formValues.damageOnThird}
              />
            </FormControl>

            <GridItem colSpan={{ base: 1, md: 2 }}>
              <FormControl id="damageReport">
                <FormLabel>Damage report</FormLabel>
                <Textarea
                  autoComplete="off"
                  disabled={formValues.damageOnAircraft === ''}
                  onChange={handleChange}
                  placeholder="Describe briefly the occurred damage..."
                  rows={6}
                  value={formValues.damageReport}
                />
              </FormControl>
            </GridItem>
          </Grid>
        </Fieldset>

        <Fieldset mb={10}>
          <Legend icon={FaPaperclip}>Attachments</Legend>
          <VStack align="start" spacing={6}>
            {formValues.attachments.map((_, index) => (
              <Input
                key={index}
                accept=".pdf,.doc,.docx,.xls,.xlsx,.jpg,.png,.jpeg,.heic"
                aria-label="Upload attachment"
                onChange={(event) => handleFileSelect(event, index)}
                type="file"
                variant="unstyled"
              />
            ))}
            <Text color="gray.500">
              Allowed filetypes: PDF, DOC, XLS, JPG, JPEG, PNG, HEIC
            </Text>
            <Button
              onClick={handleAddMoreAttachment}
              leftIcon={<Icon as={FaPlus} />}
              variant="outline"
            >
              More
            </Button>
          </VStack>
        </Fieldset>

        <Divider />

        <Checkbox
          id="anonym"
          onChange={handleChange}
          checked={formValues.anonym}
          pt={6}
          pb={4}
        >
          Send anonymously
        </Checkbox>
        <HStack spacing={4}>
          <Button
            colorScheme="blue"
            leftIcon={<Icon as={FaPaperPlane} />}
            onClick={handleSubmit}
          >
            Send
          </Button>
          <Button
            onClick={handleCancel}
            leftIcon={<Icon as={FaTimes} />}
            variant="outline"
          >
            Cancel
          </Button>
        </HStack>
      </form>
    </>
  )
}

export default ReportNew
