import { Button } from '@chakra-ui/button'
import { useColorModeValue } from '@chakra-ui/color-mode'
import Icon from '@chakra-ui/icon'
import {
  Box,
  Divider,
  Heading,
  HStack,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/layout'
import { CircularProgress } from '@chakra-ui/progress'
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table'
import { Tag } from '@chakra-ui/tag'
import moment from 'moment'
import React from 'react'
import { FaPaperclip, FaPlus, FaRegComment, FaRegFolder } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
import TimeAgo from 'react-timeago'

import { StateType } from '../../generated/types'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import { getTheme } from '../../theme'
import { formatter } from '../../utils/formatter'
import PageTitle from '../PageTitle'
import { DashboardQuery, useDashboardQuery } from './dashboard.generated'

const buildInfo = (report: DashboardQuery['reports'][0]) => {
  const date = report.date
  const time = report.time

  const timeString =
    date && time ? `${date} - ${time}` : date ? date : time ? time : undefined

  return (
    <Box alignItems="center" display="flex" justifyContent="space-between">
      <Box>
        <Box pb={1}>
          <Text as="span" color="gray.500" fontSize="small" mr={2}>
            Date of incident:
          </Text>
          <Text as="span">{timeString || '-'}</Text>
        </Box>
        <Box py={1}>
          <Text as="span" color="gray.500" fontSize="small" mr={2}>
            Location:
          </Text>
          <Text as="span">{report.location || '-'}</Text>
        </Box>
        <Box pt={1}>
          <Text as="span" color="gray.500" fontSize="small" mr={2}>
            Aircraft:
          </Text>
          <Text as="span">{report.aircraft || '-'}</Text>
        </Box>
      </Box>
      {report.numOfComments || report.numOfAttachments ? (
        <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
          {report.numOfComments ? (
            <Box
              aria-label="comments"
              alignItems="center"
              color="gray.500"
              display="flex"
              justifyContent="center"
            >
              <Icon as={FaRegComment} mr={2} /> {report.numOfComments}
            </Box>
          ) : null}
          {report.numOfAttachments ? (
            <Box
              aria-label="attachments"
              alignItems="center"
              color="gray.500"
              display="flex"
              justifyContent="center"
            >
              <Icon as={FaPaperclip} mr={2} /> {report.numOfAttachments}
            </Box>
          ) : null}
        </Stack>
      ) : null}
    </Box>
  )
}

const Dashboard: React.FC = () => {
  const { data, loading, error } = useDashboardQuery({
    fetchPolicy: 'cache-and-network',
  })
  const theadBgColor = useColorModeValue('gray.50', 'gray.800')
  const isMobile = useMediaQuery(`(max-width: ${getTheme().breakpoints.sm})`)

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress isIndeterminate />
      </Box>
    )
  }

  if (error || !data) {
    return <div>Error</div>
  }

  if (data.reports.length === 0) {
    return (
      <Box textAlign="center">
        <Icon
          as={FaRegFolder}
          color="gray.400"
          height={12}
          mx="auto"
          width={12}
        />
        <Heading as="h2" mt={2} fontSize="medium" size="sm">
          No reports
        </Heading>
        <Text as="p" color="gray.500" mt={1} fontSize="medium">
          Get started by creating a new report.
        </Text>
        <Box mt={6}>
          <Button as={NavLink} leftIcon={<Icon as={FaPlus} />} to="/report/new">
            New Report
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <>
      <PageTitle
        action={
          <Button
            as={NavLink}
            leftIcon={<Icon as={FaPlus} />}
            to="/report/new"
            variant="outline"
          >
            New Report
          </Button>
        }
        title="Dashboard"
      />

      {isMobile ? (
        <>
          {data.reports.map((report) => {
            return (
              <Box borderWidth="thin" key={report.id} my={6} p={4}>
                <VStack alignItems="flex-start" spacing={4}>
                  <Text color="gray.500" fontSize="small" mt={1}>
                    <TimeAgo
                      date={moment.unix(report.created).toDate()}
                      formatter={formatter}
                      title={moment
                        .unix(report.created)
                        .format('DD.MM.YYYY HH:mm')}
                    />{' '}
                    by {report.user || 'unknown'}
                  </Text>
                  <HStack justifyContent="space-between" width="full">
                    {report.state === StateType.Active ? (
                      <Tag colorScheme="green">Active</Tag>
                    ) : (
                      <Tag colorScheme="red">Closed</Tag>
                    )}
                    <Button
                      as={NavLink}
                      color="blue.500"
                      to={`/report/${report.id}`}
                      variant="link"
                    >
                      View
                    </Button>
                  </HStack>
                  <Divider />
                  <Box width="100%">{buildInfo(report)}</Box>
                </VStack>
              </Box>
            )
          })}
        </>
      ) : (
        <>
          <Table
            borderWidth="thin"
            my={8}
            variant="simple"
            aria-label="Reports"
          >
            <Thead bgColor={theadBgColor}>
              <Tr>
                <Th>Created</Th>
                <Th>Info</Th>
                <Th>State</Th>
                <Th>&nbsp;</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.reports.map((report) => (
                <Tr key={report.id} data-testid={`reportRow${report.id}`}>
                  <Td>
                    <TimeAgo
                      date={moment.unix(report.created).toDate()}
                      formatter={formatter}
                      title={moment
                        .unix(report.created)
                        .format('DD.MM.YYYY HH:mm')}
                    />
                    <Text color="gray.500" fontSize="small" mt={1}>
                      by {report.user || 'unknown'}
                    </Text>
                  </Td>
                  <Td>{buildInfo(report)}</Td>
                  <Td>
                    {report.state === StateType.Active ? (
                      <Tag colorScheme="green">Active</Tag>
                    ) : (
                      <Tag colorScheme="red">Closed</Tag>
                    )}
                  </Td>
                  <Td width={8}>
                    <Button
                      as={NavLink}
                      color="blue.500"
                      to={`/report/${report.id}`}
                      variant="link"
                    >
                      View
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </>
      )}
    </>
  )
}

export default Dashboard
