import * as Types from '../../generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type CreateReportMutationVariables = Types.Exact<{
  location: Types.Scalars['String']
  date: Types.Scalars['String']
  time: Types.Scalars['String']
  aircraft: Types.Scalars['String']
  flightnumber: Types.Scalars['String']
  departure: Types.Scalars['String']
  destination: Types.Scalars['String']
  flightplan: Types.Scalars['String']
  pilot: Types.Scalars['String']
  numOfCrew: Types.Scalars['String']
  numOfPax: Types.Scalars['String']
  hasDangerousGoods: Types.Scalars['Boolean']
  dangerousGoods: Types.Scalars['String']
  flighttype: Types.Scalars['String']
  flightrules: Types.Scalars['String']
  ias: Types.Scalars['String']
  flightphase: Types.Scalars['String']
  light: Types.Scalars['String']
  approach: Types.Scalars['String']
  runway: Types.Scalars['String']
  airspace: Types.Scalars['String']
  weather: Types.Scalars['String']
  weatherIntensity: Types.Scalars['String']
  oat: Types.Scalars['String']
  wind: Types.Scalars['String']
  windDirection: Types.Scalars['String']
  windSpeed: Types.Scalars['String']
  turbulences: Types.Scalars['String']
  icing: Types.Scalars['String']
  report: Types.Scalars['String']
  fatalCrew: Types.Scalars['String']
  fatalPax: Types.Scalars['String']
  fatalOthers: Types.Scalars['String']
  seriousCrew: Types.Scalars['String']
  seriousPax: Types.Scalars['String']
  seriousOthers: Types.Scalars['String']
  minorCrew: Types.Scalars['String']
  minorPax: Types.Scalars['String']
  minorOthers: Types.Scalars['String']
  noneCrew: Types.Scalars['String']
  nonePax: Types.Scalars['String']
  noneOthers: Types.Scalars['String']
  damageOnAircraft: Types.Scalars['String']
  damageOnThird: Types.Scalars['String']
  damageReport: Types.Scalars['String']
  anonym: Types.Scalars['Boolean']
  attachments?: Types.InputMaybe<
    Array<Types.Scalars['Upload']> | Types.Scalars['Upload']
  >
}>

export type CreateReportMutation = {
  __typename?: 'Mutation'
  createReport: {
    __typename?: 'CreateReportPayload'
    data?: { __typename?: 'Report'; id: string } | null
  }
}

export const CreateReportDocument = gql`
  mutation CreateReport(
    $location: String!
    $date: String!
    $time: String!
    $aircraft: String!
    $flightnumber: String!
    $departure: String!
    $destination: String!
    $flightplan: String!
    $pilot: String!
    $numOfCrew: String!
    $numOfPax: String!
    $hasDangerousGoods: Boolean!
    $dangerousGoods: String!
    $flighttype: String!
    $flightrules: String!
    $ias: String!
    $flightphase: String!
    $light: String!
    $approach: String!
    $runway: String!
    $airspace: String!
    $weather: String!
    $weatherIntensity: String!
    $oat: String!
    $wind: String!
    $windDirection: String!
    $windSpeed: String!
    $turbulences: String!
    $icing: String!
    $report: String!
    $fatalCrew: String!
    $fatalPax: String!
    $fatalOthers: String!
    $seriousCrew: String!
    $seriousPax: String!
    $seriousOthers: String!
    $minorCrew: String!
    $minorPax: String!
    $minorOthers: String!
    $noneCrew: String!
    $nonePax: String!
    $noneOthers: String!
    $damageOnAircraft: String!
    $damageOnThird: String!
    $damageReport: String!
    $anonym: Boolean!
    $attachments: [Upload!]
  ) {
    createReport(
      input: {
        location: $location
        date: $date
        time: $time
        aircraft: $aircraft
        flightnumber: $flightnumber
        departure: $departure
        destination: $destination
        flightplan: $flightplan
        pilot: $pilot
        numOfCrew: $numOfCrew
        numOfPax: $numOfPax
        hasDangerousGoods: $hasDangerousGoods
        dangerousGoods: $dangerousGoods
        flighttype: $flighttype
        flightrules: $flightrules
        ias: $ias
        flightphase: $flightphase
        light: $light
        approach: $approach
        runway: $runway
        airspace: $airspace
        weather: $weather
        weatherIntensity: $weatherIntensity
        oat: $oat
        wind: $wind
        windDirection: $windDirection
        windSpeed: $windSpeed
        turbulences: $turbulences
        icing: $icing
        report: $report
        fatalCrew: $fatalCrew
        fatalPax: $fatalPax
        fatalOthers: $fatalOthers
        seriousCrew: $seriousCrew
        seriousPax: $seriousPax
        seriousOthers: $seriousOthers
        minorCrew: $minorCrew
        minorPax: $minorPax
        minorOthers: $minorOthers
        noneCrew: $noneCrew
        nonePax: $nonePax
        noneOthers: $noneOthers
        damageOnAircraft: $damageOnAircraft
        damageOnThird: $damageOnThird
        damageReport: $damageReport
        anonym: $anonym
        attachments: $attachments
      }
    ) {
      data {
        id
      }
    }
  }
`
export type CreateReportMutationFn = Apollo.MutationFunction<
  CreateReportMutation,
  CreateReportMutationVariables
>

/**
 * __useCreateReportMutation__
 *
 * To run a mutation, you first call `useCreateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportMutation, { data, loading, error }] = useCreateReportMutation({
 *   variables: {
 *      location: // value for 'location'
 *      date: // value for 'date'
 *      time: // value for 'time'
 *      aircraft: // value for 'aircraft'
 *      flightnumber: // value for 'flightnumber'
 *      departure: // value for 'departure'
 *      destination: // value for 'destination'
 *      flightplan: // value for 'flightplan'
 *      pilot: // value for 'pilot'
 *      numOfCrew: // value for 'numOfCrew'
 *      numOfPax: // value for 'numOfPax'
 *      hasDangerousGoods: // value for 'hasDangerousGoods'
 *      dangerousGoods: // value for 'dangerousGoods'
 *      flighttype: // value for 'flighttype'
 *      flightrules: // value for 'flightrules'
 *      ias: // value for 'ias'
 *      flightphase: // value for 'flightphase'
 *      light: // value for 'light'
 *      approach: // value for 'approach'
 *      runway: // value for 'runway'
 *      airspace: // value for 'airspace'
 *      weather: // value for 'weather'
 *      weatherIntensity: // value for 'weatherIntensity'
 *      oat: // value for 'oat'
 *      wind: // value for 'wind'
 *      windDirection: // value for 'windDirection'
 *      windSpeed: // value for 'windSpeed'
 *      turbulences: // value for 'turbulences'
 *      icing: // value for 'icing'
 *      report: // value for 'report'
 *      fatalCrew: // value for 'fatalCrew'
 *      fatalPax: // value for 'fatalPax'
 *      fatalOthers: // value for 'fatalOthers'
 *      seriousCrew: // value for 'seriousCrew'
 *      seriousPax: // value for 'seriousPax'
 *      seriousOthers: // value for 'seriousOthers'
 *      minorCrew: // value for 'minorCrew'
 *      minorPax: // value for 'minorPax'
 *      minorOthers: // value for 'minorOthers'
 *      noneCrew: // value for 'noneCrew'
 *      nonePax: // value for 'nonePax'
 *      noneOthers: // value for 'noneOthers'
 *      damageOnAircraft: // value for 'damageOnAircraft'
 *      damageOnThird: // value for 'damageOnThird'
 *      damageReport: // value for 'damageReport'
 *      anonym: // value for 'anonym'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useCreateReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateReportMutation,
    CreateReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateReportMutation,
    CreateReportMutationVariables
  >(CreateReportDocument, options)
}
export type CreateReportMutationHookResult = ReturnType<
  typeof useCreateReportMutation
>
export type CreateReportMutationResult =
  Apollo.MutationResult<CreateReportMutation>
export type CreateReportMutationOptions = Apollo.BaseMutationOptions<
  CreateReportMutation,
  CreateReportMutationVariables
>
