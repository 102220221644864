export const baseURL =
  process.env.NODE_ENV === 'production'
    ? 'https://api.aerodesk.de'
    : 'http://localhost:4000/'

export const authURL =
  process.env.NODE_ENV === 'production'
    ? 'https://auth.aerodesk.de'
    : 'http://localhost:4001'

export const storageKeys = {
  AERODESK_USER: 'AERODESK_USER',
}
