import { Avatar } from '@chakra-ui/avatar'
import { Button } from '@chakra-ui/button'
import { useColorMode, useColorModeValue } from '@chakra-ui/color-mode'
import Icon from '@chakra-ui/icon'
import { Image } from '@chakra-ui/image'
import { Box, Flex, Heading, HStack, Text } from '@chakra-ui/layout'
import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from '@chakra-ui/menu'
import '@fontsource/fredoka-one'
import React from 'react'
import {
  FaMoon,
  FaSignOutAlt,
  FaSun,
  FaUserCircle,
  FaUsers,
} from 'react-icons/fa'
import { NavLink } from 'react-router-dom'

import logo from '../../assets/logo.svg'
import { useAuth } from '../AuthProvider'

const Frame: React.FC = ({ children }) => {
  const { colorMode, toggleColorMode } = useColorMode()
  const auth = useAuth()

  const handleLogout = async () => {
    await auth.signout()
  }

  const borderBottomColor = useColorModeValue('gray.200', 'gray.600')

  return (
    <>
      {/* Header */}
      <Box
        as="header"
        borderBottomWidth="1px"
        borderBottomColor={borderBottomColor}
      >
        <Flex
          alignItems="center"
          height={16}
          justifyContent="space-between"
          paddingX={{ base: 4, md: 6, lg: 8 }}
        >
          <Flex alignItems="flex-end">
            <HStack spacing={3}>
              <Image alt="Aerodesk" src={logo} height={8} width="auto" />
              <Heading
                as="h1"
                color="cyan.400"
                display={{ base: 'none', lg: 'inline' }}
                fontFamily="Fredoka One"
                isTruncated
                size="lg"
              >
                Aerodesk
              </Heading>
            </HStack>
            <Box marginLeft={6}>
              <Button as={NavLink} to="/dashboard">
                Dashboard
              </Button>
            </Box>
          </Flex>

          <HStack spacing={4}>
            <Button onClick={toggleColorMode}>
              <Icon
                aria-label="Farbschema ändern"
                as={colorMode === 'light' ? FaMoon : FaSun}
              />
            </Button>
            <Box>
              <Menu autoSelect={false}>
                <MenuButton as={Button}>
                  <Icon
                    aria-label="Open user menu"
                    display="flex"
                    as={FaUserCircle}
                    alignItems="center"
                    justifyContent="center"
                  />
                </MenuButton>
                <MenuList aria-label="User menu" fontSize="sm">
                  <HStack pb={2} pt={1} px={3} spacing={4}>
                    <Avatar size="sm" />
                    <Box>
                      <Text fontWeight="semibold">{auth.user?.name}</Text>
                      {false && (
                        <Text color="gray.500" fontSize="xs">
                          {auth.user}
                        </Text>
                      )}
                    </Box>
                  </HStack>
                  <MenuDivider />
                  {/* <MenuItem icon={<Icon aria-label="Settings" as={FaCogs} />}>
                  Settings
                </MenuItem>
                <MenuDivider /> */}
                  <MenuItem
                    as={NavLink}
                    fontWeight="medium"
                    icon={
                      <Icon
                        aria-label="User management"
                        as={FaUsers}
                        height={4}
                        width={4}
                      />
                    }
                    to="/users"
                  >
                    User management
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem
                    fontWeight="medium"
                    icon={
                      <Icon
                        aria-label="Sign out"
                        as={FaSignOutAlt}
                        height={4}
                        width={4}
                      />
                    }
                    onClick={handleLogout}
                  >
                    Sign out
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </HStack>
        </Flex>
      </Box>

      {/* Main */}
      <Box as="main" py={10}>
        <Box maxW="8xl" mx="auto" paddingX={{ base: 4, sm: 6, lg: 8 }}>
          {children}
        </Box>
      </Box>
    </>
  )
}

export default Frame
