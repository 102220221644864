import Icon from '@chakra-ui/icon'
import { Box, HStack, VStack } from '@chakra-ui/layout'
import moment from 'moment'
import React from 'react'
import { FaRegCheckCircle, FaRegComment, FaRegDotCircle } from 'react-icons/fa'
import TimeAgo from 'react-timeago'

import { StateType } from '../../../generated/types'
import { formatter } from '../../../utils/formatter'

const isComment = (activity: Comment | State): activity is Comment => {
  return (activity as Comment).comment !== undefined
}

type Comment = {
  id: string
  comment: string
}

type State = {
  id: string
  state: StateType
}

type Activity = {
  id: string
  timestamp: number
  user?: string | null
  data: Comment | State
}

type ActivitiesProps = {
  activities: Activity[]
}

export const Activities: React.FC<ActivitiesProps> = ({ activities }) => {
  return (
    <VStack as="ul" maxWidth="2xl" py={4}>
      {activities.map((activity, index) => {
        const showTimeline = index !== activities.length - 1

        if (isComment(activity.data)) {
          return (
            <HStack
              alignItems="normal"
              key={activity.id}
              as="li"
              spacing={4}
              width="full"
            >
              <>
                <Box
                  alignItems="center"
                  aria-hidden="true"
                  display="flex"
                  flexDirection="column"
                >
                  <Box display="flex" flex="0 0" mb={3}>
                    <Icon
                      as={FaRegComment}
                      color="gray.500"
                      height={6}
                      width={6}
                    />
                  </Box>
                  {<Box borderRightWidth={1} display="flex" flex="1 1" />}
                </Box>
                <VStack alignItems="flex-start" fontSize="sm">
                  <Box>
                    <Box as="span" fontWeight="semibold">
                      {activity.user || 'Unknown'}
                    </Box>
                    <Box as="span" color="gray.500">
                      {' '}
                      commented{' '}
                      <TimeAgo
                        date={moment.unix(activity.timestamp).toDate()}
                        formatter={formatter}
                        title={moment(activity.timestamp).format(
                          'DD.MM.YYYY HH:mm'
                        )}
                      />
                    </Box>
                  </Box>
                  <Box pb={6}>
                    {activity.data.comment.split('\n').map((value, index) => {
                      return (
                        <React.Fragment key={index}>
                          {value}
                          <br />
                        </React.Fragment>
                      )
                    })}
                  </Box>
                </VStack>
              </>
            </HStack>
          )
        }

        return (
          <HStack
            alignItems="normal"
            key={activity.id}
            as="li"
            spacing={4}
            width="full"
          >
            <>
              <Box
                alignItems="center"
                aria-hidden="true"
                display="flex"
                flexDirection="column"
              >
                <Box
                  display="flex"
                  flex="0 0"
                  justifyContent="center"
                  width={6}
                  mb={showTimeline ? 2 : 0}
                >
                  <Icon
                    as={
                      activity.data.state === StateType.Closed
                        ? FaRegCheckCircle
                        : FaRegDotCircle
                    }
                    color={
                      activity.data.state === StateType.Closed
                        ? 'red.500'
                        : 'green.500'
                    }
                    mt={1}
                  />
                </Box>
                {showTimeline && (
                  <Box borderRightWidth={1} display="flex" flex="1 1" />
                )}
              </Box>
              <VStack
                alignItems="flex-start"
                fontSize="sm"
                pb={showTimeline ? 6 : 0}
              >
                <Box>
                  <Box as="span" fontWeight="semibold">
                    {activity.user || 'Unknown'}
                  </Box>
                  <Box as="span" color="gray.500">
                    {' '}
                    {activity.data.state === StateType.Closed
                      ? 'closed'
                      : 'reopened'}{' '}
                    this report{' '}
                    <TimeAgo
                      date={moment.unix(activity.timestamp).toDate()}
                      formatter={formatter}
                      title={moment(activity.timestamp).format(
                        'DD.MM.YYYY HH:mm'
                      )}
                    />
                  </Box>
                </Box>
              </VStack>
            </>
          </HStack>
        )
      })}
    </VStack>
  )
}
