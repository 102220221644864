import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Progress,
  Select,
  Switch,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import React, { useRef, useState } from 'react'
import { Field, Form } from 'react-final-form'
import {
  FaArrowRight,
  FaBell,
  FaRedo,
  FaRegAddressCard,
  FaSadTear,
  FaSave,
  FaTimes,
  FaTrash,
  FaUser,
  FaUserCircle,
} from 'react-icons/fa'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import zxcvbn from 'zxcvbn'

import { composeValidators } from '../../utils/validators/composeValidators'
import { mustBeEmail } from '../../utils/validators/mustBeEmail'
import { required } from '../../utils/validators/required'
import PageTitle from '../PageTitle'
import Fieldset from '../ReportNew/Fieldset'
import Legend from '../ReportNew/Legend'
import { useUserEditQuery, useUserEditRolesQuery } from './userEdit.generated'

type FormFields = {
  user: string
  password?: string
  role: string
  photo?: string
  notifications: boolean
}

const UserEdit: React.FC = () => {
  const { id = '' } = useParams()
  const navigate = useNavigate()

  const [deleteUserDialogIsOpen, setDeleteUserDialogIsOpen] = useState(false)
  const cancelDeleteUserRef = useRef<HTMLButtonElement | null>(null)

  const descriptionTextColor = useColorModeValue('gray.500', 'gray.400')

  const { data, loading, error, refetch } = useUserEditQuery({
    variables: { id: id },
  })
  const { data: dataRoles, loading: loadingRoles } = useUserEditRolesQuery()

  if (loading || loadingRoles) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress isIndeterminate />
      </Box>
    )
  }

  if (error || !data || !data.users || data.users.length !== 1) {
    return (
      <Box textAlign="center">
        <Icon
          as={FaSadTear}
          color="gray.400"
          height={12}
          mx="auto"
          width={12}
        />
        <Heading as="h2" mt={2} fontSize="medium" size="sm">
          Error
        </Heading>
        <Text as="p" color="gray.500" mt={1} fontSize="medium">
          Sorry, the user could not be loaded.
        </Text>
        <Box mt={6}>
          <HStack justifyContent="center" spacing={12}>
            <Button leftIcon={<Icon as={FaRedo} />} onClick={() => refetch()}>
              Try again
            </Button>
            <Button
              as={NavLink}
              leftIcon={<Icon as={FaArrowRight} />}
              to="/users"
            >
              Users
            </Button>
          </HStack>
        </Box>
      </Box>
    )
  }

  const handleCancel = () => {
    navigate(-1)
  }

  const handleDeleteUserDialogCancel = () => setDeleteUserDialogIsOpen(false)

  const handleDeleteUserDialogConfirm = () => {
    alert('You deleted the user!')
    setDeleteUserDialogIsOpen(false)
  }

  const handleDeleteUserDialogOpen = () => setDeleteUserDialogIsOpen(true)

  const handleSubmit = async (values: FormFields) => {
    console.log(values)
  }

  return (
    <>
      <PageTitle icon={FaUser} title="Edit user" />
      <Form
        initialValues={{
          user: data.users[0].name,
          role: data.users[0].role.id,
          notifications: true,
        }}
        onSubmit={handleSubmit}
        render={({ handleSubmit, submitting }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Fieldset>
              <Legend icon={FaUserCircle}>Account information</Legend>
              <Text color={descriptionTextColor} fontSize="sm" mt={-4}>
                General information about the user.
              </Text>
              <Field
                name="user"
                render={({
                  input,
                  meta: { dirtySinceLastSubmit, error, submitError, touched },
                }) => (
                  <FormControl
                    isReadOnly
                    isInvalid={
                      (error || (submitError && !dirtySinceLastSubmit)) &&
                      touched
                    }
                    maxWidth="xl"
                    mt={8}
                  >
                    <FormLabel htmlFor="user">Email address</FormLabel>
                    <InputGroup>
                      <Input
                        {...input}
                        autoComplete="off"
                        id="user"
                        placeholder="Email address"
                        type="email"
                      />
                      {submitting && (
                        <InputRightElement>
                          <CircularProgress
                            isIndeterminate
                            color="blue.300"
                            size="1rem"
                          />
                        </InputRightElement>
                      )}
                    </InputGroup>
                    <FormErrorMessage>{error || submitError}</FormErrorMessage>
                  </FormControl>
                )}
                validate={composeValidators(required, mustBeEmail)}
              />

              <Field
                name="password"
                render={({ input, meta: { error, touched } }) => {
                  const passwordStrength = input.value
                    ? (zxcvbn(input.value).score + 1) * 20
                    : 0

                  return (
                    <FormControl
                      isInvalid={error && touched}
                      maxWidth="xl"
                      mt={8}
                    >
                      <FormLabel htmlFor="password">Password</FormLabel>
                      <Input
                        {...input}
                        autoComplete="off"
                        id="password"
                        placeholder="Password"
                        type="password"
                      />
                      <Progress
                        aria-label="Password Strength"
                        colorScheme={
                          passwordStrength < 20
                            ? 'gray'
                            : passwordStrength < 60
                            ? 'red'
                            : passwordStrength < 80
                            ? 'yellow'
                            : 'green'
                        }
                        mt={2}
                        value={passwordStrength}
                      />
                      <FormErrorMessage>{error}</FormErrorMessage>
                    </FormControl>
                  )
                }}
                validate={required}
              />

              <Field
                name="role"
                render={({ input, meta: { error, touched } }) => (
                  <FormControl
                    isInvalid={error && touched}
                    isRequired
                    maxWidth="xl"
                    mt={8}
                  >
                    <FormLabel htmlFor="role">Role</FormLabel>
                    <Select {...input} id="role" placeholder="Select...">
                      {dataRoles &&
                        dataRoles.roles.map((role) => (
                          <option key={role.id} value={role.id}>
                            {role.name}
                          </option>
                        ))}
                    </Select>
                    <FormErrorMessage>{error}</FormErrorMessage>
                  </FormControl>
                )}
                validate={required}
              />
            </Fieldset>

            <Fieldset>
              <Legend icon={FaRegAddressCard}>Personal information</Legend>
              <Text color={descriptionTextColor} fontSize="sm" mt={-4}>
                Detailed information about the user. This information will not
                be displayed.
              </Text>

              <Grid
                templateColumns={{
                  base: 'repeat(1, 1fr)',
                  md: 'repeat(2, 1fr)',
                }}
                gap={6}
                maxWidth="3xl"
              >
                <Field
                  name="firstname"
                  render={({ input }) => (
                    <FormControl maxWidth="md" mt={8}>
                      <FormLabel htmlFor="firstname">First name</FormLabel>
                      <Input
                        {...input}
                        autoComplete="off"
                        id="firstname"
                        placeholder="First name"
                        type="text"
                      />
                    </FormControl>
                  )}
                />

                <Field
                  name="lastname"
                  render={({ input }) => (
                    <FormControl maxWidth="md" mt={8}>
                      <FormLabel htmlFor="lastname">Last name</FormLabel>
                      <Input
                        {...input}
                        autoComplete="off"
                        id="lastname"
                        placeholder="Last name"
                        type="text"
                      />
                    </FormControl>
                  )}
                />
              </Grid>
            </Fieldset>

            <Fieldset>
              <Legend icon={FaBell}>Notificiations</Legend>
              <Text color={descriptionTextColor} fontSize="sm" mt={-4}>
                Decide whether this user should get system notifications.
              </Text>

              <Field
                name="notifications"
                render={({ input }) => (
                  <FormControl alignItems="center" display="flex" mt={8}>
                    <Switch
                      {...input}
                      id="notifications"
                      isChecked={input.checked}
                    />
                    <FormLabel htmlFor="notifications" mb={0} ml={4}>
                      Email
                    </FormLabel>
                  </FormControl>
                )}
                type="checkbox"
              />
            </Fieldset>

            <HStack spacing={4}>
              <Button
                colorScheme="blue"
                disabled={submitting}
                leftIcon={<Icon as={FaSave} />}
                type="submit"
              >
                Save
              </Button>
              <Button
                disabled={submitting}
                leftIcon={<Icon as={FaTimes} />}
                onClick={handleCancel}
                variant="outline"
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                disabled={submitting}
                leftIcon={<Icon as={FaTrash} />}
                onClick={handleDeleteUserDialogOpen}
                variant="outline"
              >
                Delete user
              </Button>
            </HStack>
          </form>
        )}
        subscription={{ pristine: true, submitting: true }}
      />
      <AlertDialog
        isOpen={deleteUserDialogIsOpen}
        leastDestructiveRef={cancelDeleteUserRef}
        onClose={handleDeleteUserDialogCancel}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete user
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can&apos;t undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelDeleteUserRef}
                onClick={handleDeleteUserDialogCancel}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={handleDeleteUserDialogConfirm}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default UserEdit
