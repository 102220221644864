import * as Types from '../../generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type DashboardQueryVariables = Types.Exact<{ [key: string]: never }>

export type DashboardQuery = {
  __typename?: 'Query'
  reports: Array<{
    __typename?: 'Report'
    id: string
    location: string
    date: string
    time: string
    aircraft: string
    flightnumber: string
    created: number
    state: Types.StateType
    user?: string | null
    numOfComments: number
    numOfAttachments: number
  }>
}

export const DashboardDocument = gql`
  query Dashboard {
    reports {
      id
      location
      date
      time
      aircraft
      flightnumber
      created
      state
      user
      numOfComments
      numOfAttachments
    }
  }
`

/**
 * __useDashboardQuery__
 *
 * To run a query within a React component, call `useDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardQuery(
  baseOptions?: Apollo.QueryHookOptions<DashboardQuery, DashboardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DashboardQuery, DashboardQueryVariables>(
    DashboardDocument,
    options
  )
}
export function useDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardQuery,
    DashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DashboardQuery, DashboardQueryVariables>(
    DashboardDocument,
    options
  )
}
export type DashboardQueryHookResult = ReturnType<typeof useDashboardQuery>
export type DashboardLazyQueryHookResult = ReturnType<
  typeof useDashboardLazyQuery
>
export type DashboardQueryResult = Apollo.QueryResult<
  DashboardQuery,
  DashboardQueryVariables
>
