import { factory, oneOf, primaryKey } from '@mswjs/data'
import { address, datatype, date, finance, name, time, vehicle } from 'faker'

import { StateType } from '../../generated/types'

export const db = factory({
  user: {
    id: primaryKey(() => datatype.uuid()),
    name: String,
    role: String,
    active: Boolean,
  },

  role: {
    id: primaryKey(() => datatype.uuid()),
    name: String,
    admin: Boolean,
    reportRead: Boolean,
    reportWrite: Boolean,
  },

  report: {
    id: primaryKey(() => datatype.uuid()),
    location: String,
    date: String,
    time: String,
    aircraft: String,
    flightnumber: String,
    departure: String,
    destination: String,
    flightplan: String,
    pilot: String,
    numOfCrew: String,
    numOfPax: String,
    dangerousGoodsChecked: Boolean,
    dangerousGoods: String,
    flighttype: String,
    flightrules: String,
    ias: String,
    flightphase: String,
    light: String,
    approach: String,
    runway: String,
    airspace: String,
    weather: String,
    weatherIntensity: String,
    oat: String,
    wind: String,
    windDirection: String,
    windSpeed: String,
    turbulences: String,
    icing: String,
    report: String,
    fatalCrew: String,
    fatalPax: String,
    fatalOthers: String,
    seriousCrew: String,
    seriousPax: String,
    seriousOthers: String,
    minorCrew: String,
    minorPax: String,
    minorOthers: String,
    noneCrew: String,
    nonePax: String,
    noneOthers: String,
    damageOnAircraft: String,
    damageOnThird: String,
    damageReport: String,
    created: Number,
    user: String,
    state: () => StateType.Active,
  },

  activity: {
    id: primaryKey(() => datatype.uuid()),
    report: oneOf('report'),
    timestamp: Number,
    user: String,
    type: String,
  },

  state: {
    id: primaryKey(() => datatype.uuid()),
    state: () => StateType.Active,
    activity: oneOf('activity'),
  },

  comment: {
    id: primaryKey(() => datatype.uuid()),
    comment: String,
    activity: oneOf('activity'),
  },
})

db.role.create({
  name: 'Manager',
  admin: true,
  reportRead: true,
  reportWrite: true,
})

db.role.create({
  name: 'Reporter',
  admin: false,
  reportRead: true,
  reportWrite: true,
})

db.user.create({
  name: 'manager@fmt.com',
  role: db.role.findFirst({ where: { name: { equals: 'Manager' } } })?.id || '',
  active: true,
})

db.user.create({
  name: 'reporter@fmt.com',
  role:
    db.role.findFirst({ where: { name: { equals: 'Reporter' } } })?.id || '',
  active: true,
})

db.user.create({
  name: 'pilot@fmt.com',
  role:
    db.role.findFirst({ where: { name: { equals: 'Reporter' } } })?.id || '',
  active: true,
})

if (process.env.NODE_ENV === 'test') {
  db.user.create({
    name: 'tester@fmt.com',
    role:
      db.role.findFirst({ where: { name: { equals: 'Reporter' } } })?.id || '',
    active: true,
  })
}

export const seedReports = (numberOfReports = 15): void => {
  const role = db.role.findFirst({ where: { name: { equals: 'Reporter' } } })
  const reporter = db.user.findMany({ where: { role: { equals: role?.id } } })

  for (let i = 0; i < numberOfReports; i++) {
    db.report.create({
      location: i % 4 === 0 ? address.city() : undefined,
      date: date.recent().getTime().toString(),
      time: time.recent().toString(),
      aircraft: i % 2 === 0 ? vehicle.manufacturer() : undefined,
      flightnumber: i % 2 === 0 ? vehicle.vrm() : undefined,
      departure: i % 4 === 0 ? address.city() : undefined,
      destination: i % 4 === 0 ? address.city() : undefined,
      flightplan:
        i % 3 === 0
          ? `${address.stateAbbr()}${finance.creditCardCVV()}`
          : undefined,
      pilot: i % 2 === 0 ? `${name.firstName()} ${name.lastName()}` : undefined,
      numOfCrew: i % 2 === 0 ? `${datatype.number(2)}` : undefined,
      numOfPax: i % 2 === 0 ? `${datatype.number(14)}` : undefined,
      dangerousGoodsChecked: false,
      dangerousGoods: '',
      flighttype: 'Training',
      flightrules: i % 5 === 0 ? 'VFR' : 'IFR',
      ias: `${datatype.number(300)}`,
      flightphase: 'Landing',
      light: 'Daylight',
      approach: 'VOR',
      runway: 'Dry',
      airspace: 'D',
      weather: '',
      weatherIntensity: '',
      oat: `${datatype.number(25)}`,
      wind: '',
      windDirection: '',
      windSpeed: '',
      turbulences: '',
      icing: '',
      report: '',
      fatalCrew: '',
      fatalPax: '',
      fatalOthers: '',
      seriousCrew: '',
      seriousPax: '',
      seriousOthers: '',
      minorCrew: '',
      minorPax: '',
      minorOthers: '',
      noneCrew: '',
      nonePax: '',
      noneOthers: '',
      damageOnAircraft: '',
      damageOnThird: '',
      damageReport: '',
      created: date.recent().getTime(),
      user: reporter.length ? reporter[i % reporter.length].name : undefined,
      state: datatype.boolean() ? StateType.Closed : StateType.Active,
    })
  }
}
