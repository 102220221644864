import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  InMemoryCache,
} from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import React from 'react'

import { baseURL } from '../../utils/config'
import { useAuth } from '../AuthProvider'

const link = createUploadLink({
  uri: `${baseURL}`,
})

const authMiddleware = (token?: string) =>
  new ApolloLink((operation, forward) => {
    operation.setContext(() => ({
      headers: {
        auth: 'aerodesk-app',
        bearer: token,
        mode: process.env.NODE_ENV,
      },
    }))
    return forward(operation)
  })

const ClientProvider: React.FC = ({ children }) => {
  const { user } = useAuth()

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([authMiddleware(user?.token), link]),
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default ClientProvider
