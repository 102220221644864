import Icon from '@chakra-ui/icon'
import { Box } from '@chakra-ui/layout'
import React from 'react'
import { IconType } from 'react-icons'

type LegendProps = {
  icon: IconType
}

const Legend: React.FC<LegendProps> = ({ icon, children }) => {
  return (
    <Box
      as="legend"
      alignItems="center"
      borderBottomWidth="thin"
      display="flex"
      fontSize="xl"
      mb={6}
      pb={2}
      width="full"
    >
      <Icon as={icon} color="gray.500" mr={2} />
      {children}
    </Box>
  )
}

export default Legend
