import { Box } from '@chakra-ui/layout'
import React from 'react'

type FieldsetProps = React.ComponentProps<typeof Box>

const Fieldset: React.FC<FieldsetProps> = ({ children, ...rest }) => {
  return (
    <Box as="fieldset" mb={20} mt={10} {...rest}>
      {children}
    </Box>
  )
}

export default Fieldset
