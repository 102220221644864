import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { useAuth } from '../AuthProvider'
import Frame from '../Frame'

const ProtectedRoute: React.FC = () => {
  const { user } = useAuth()
  const location = useLocation()

  return user ? (
    <Frame key={Date.now()}>
      <Outlet />
    </Frame>
  ) : (
    <Navigate state={{ from: location }} to="/login" />
  )
}

export default ProtectedRoute
